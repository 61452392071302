import React, { useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getProject } from '../../../apis/project-builder';
import { useCurrentProjectIdContext } from "./project-builder-main.jsx";
import { useEditProject } from "../../../components/project-builder-custom-hooks";
import Modal from 'react-modal';
import ProjectFormFields from './project-form-fields.jsx';


export default function EditProjectModal({ buttonClass, openButtonText, projectId, setShowDropdown }) {
    Modal.setAppElement('#main');

    const { currentProjectId } = useCurrentProjectIdContext();

    const { data, isSuccess, isLoading, isError, error } = useQuery(['project-detail', projectId ? projectId : currentProjectId], () => getProject(projectId ? projectId : currentProjectId));

    const { mutate: editProject, isLoading: editIsLoading } = useEditProject(currentProjectId);

    const [isOpen, setIsOpen] = useState(false);

    const openModal = () => {
        setIsOpen(true);
    }

    const closeModal = () => {
        setIsOpen(false);
    }

    const [formData, setFormData] = useState({
        projectName: "",
        id: "",
        accountName: "",
        zipCode: "",
        phoneNumber: "",
        emailAddress: "",
        status: { value: "", label: ""},
        type: { value: "", label: "" },
        isMarketingProject: { value: "", label: "" },
        notes: "",
    });

    const handleFormSubmit = (event) => {
        event.preventDefault();
        let projectData = {
            id: data.Id,
            name: formData.projectName,
            status: formData.status.value,
            accountName: formData.accountName,
            contactPhoneNumber: formData.phoneNumber,
            contactEmailAddress: formData.emailAddress,
            zipCode: formData.zipCode,
            //freight: 85.65,
            //salesTax: 123.25,
            type: formData.type.value,
            isMarketingProject: formData.isMarketingProject.value,
            notes: formData.notes,
        }

        editProject(projectData, {
            onSuccess: () => {
                setShowDropdown ? setShowDropdown(false) : null;
                closeModal();
            }
        });

    }

    useEffect(() => {
        if(isSuccess) {
            setFormData({
                projectName: data.Name,
                id: data.Id,
                accountName: data.AccountName,
                zipCode: data.ZipCode,
                phoneNumber: data.ContactPhoneNumber,
                emailAddress: data.ContactEmailAddress,
                status: { value: data.Status, label: data.Status },
                type: { value: data.Type, label: data.Type },
                isMarketingProject: { value: data.IsMarketingProject, label: data.IsMarketingProject ? "Sales Project" : "Quote Project" },
                notes: data.Notes,
            });
        }
    }, [data]);

    return (
        <>
            {isSuccess &&
                <>
                    <button className={buttonClass} type='button' onClick={openModal}><span className="icon icon--edit"></span>{openButtonText }</button >
                    <Modal
                        isOpen={isOpen}
                        onRequestClose={closeModal}
                        className="project-builder-modal project-builder-modal--large"
                    >
                        <button className="project-builder-modal-close" onClick={closeModal}>
                            <svg id="header-ask-close-svg" className="icon icon-close"><use xlinkHref="#icon-close"></use></svg>
                        </button>
                        <div className="container project-builder">
                            <div className='modal-title'>
                                Edit Project
                            </div>

                            <form className="project-builder-form" method="post" onSubmit={handleFormSubmit}>

                                <ProjectFormFields
                                    formData={formData}
                                    setFormData={setFormData}
                                />

                                <div className="form-actions">
                                    <button className='btn btn-outline-alt' type='button' disabled={editIsLoading} onClick={closeModal} >view project</button>
                                    <button className='btn no-arrow' type='submit' disabled={editIsLoading} >save changes</button>
                                </div>
                            </form>
                        </div>
                    </Modal>
                </>
            }
        </>
    );
}