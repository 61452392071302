import React, { useState, useEffect } from 'react';
import { useNavigate, NavLink } from "react-router-dom";
import CustomContentItem from '../../../../shared/react/components/custom-content-item.jsx';
import FilterableGrid from '../../../../shared/react/components/filterable-grid.jsx';
import { postGetTranslations } from '../../../../shared/apis/translations';
import Cookies from 'js-cookie';

const DashboardModules = (props) => {
    const [modules, setModules] = useState([]);
    const [order, setOrder] = useState({
        order1: "",
        order2: "",
        order3: "",
        order4: "",
    });
    const [isLoading, setIsLoading] = useState(true);
    const [translationLookup, setTranslationLookup] = useState({});
    const translationKeys = {
        WelcomeTxt: 'Dashboard.Label.WelcomeTxt',
        ViewAllTxt: 'Dashboard.Label.ViewAllTxt',
        WelcomeTitleTxt: 'Dashboard.Label.WelcomeTitleTxt',
        CustomizeDashboardBtn: 'Dashboard.label.CustomizeDashboardBtn',
        Certificates0Txt: 'Dashboard.Label.Certificates0Txt',
        Events0Txt: 'Dashboard.Label.Events0Txt',
        NoInfoTxt: 'Dashboard.Label.NoInfoTxt',
    };

    useEffect(() => {
        postGetTranslations(Object.values(translationKeys))
            .then((translations) => {
                setTranslationLookup({
                    WelcomeTxt: translations[translationKeys.WelcomeTxt] ?? 'Welcome',
                    ViewAllTxt: translations[translationKeys.ViewAllTxt] ?? 'View All',
                    WelcomeTitleTxt: translations[translationKeys.WelcomeTitleTxt] ?? 'Welcome to Portal',
                    CustomizeDashboardBtn: translations[translationKeys.CustomizeDashboardBtn] ?? 'Button Text',
                    Certificates0Txt: translations[translationKeys.Certificates0Txt] ?? 'Certificates',
                    Events0Txt: translations[translationKeys.Events0Txt] ?? 'Events',
                    NoInfoTxt: translations[translationKeys.NoInfoTxt] ?? 'No Info',
                });
            })
            .catch((error) => {
                console.error('Error fetching translations:', error);
            });
    }, []);


    useEffect(() => {
        if (props.contact && props.contact.Email) {
            const params = { email: props.contact.Email };

            Promise.all([
                props.getQuizzesForContact(params),
                props.getEventsForContact(params)
            ]).then(() => {
                setModules(props.modules);
                if (props.modules && props.modules.length) {
                    setOrder({
                        order1: props.modules[0].NodeId,
                        order2: props.modules[1].NodeId,
                        order3: props.modules[2].NodeId,
                        order4: props.modules[3].NodeId,
                    });
                }
                setIsLoading(false);
            }).catch(error => {
                console.error("Failed to fetch data:", error);
                setIsLoading(false);
            });
        } else {
            setIsLoading(false);
        }
    }, [props.contact, props.modules]);

    const gravatarImg = "/img/generic-avatar.png";
    const navigate = useNavigate();

    const saveOrder = () => {
        console.log(order);
        props.updateOrder(order);
    };

    const handleChange = (e) => {
        setOrder({
            ...order,
            [e.target.name]: e.target.value
        });
    };

    const navigateToModule = (item) => {
        navigate("/module", { state: { module: item, notify: false } });
    };

    const navigateToNotifications = (item) => {
        Cookies.set("Notified", true);
        navigate("/module", { state: { pages: item, notify: true } });
    };

    const getFilterableGridHeading = (heading, headingType) => {
        switch (headingType) {
            case "default":
                return (<h2 className="heading">{heading}</h2>);
            case "section":
                return (
                    <div className="section-header">
                        <h2 className="section-title"><span>{heading}</span></h2>
                    </div>
                );
            case "centered":
                return (
                    <div className="row justify-content-center">
                        <h2 className="heading">{heading}</h2>
                    </div>
                );
            case "right":
                return (
                    <div className="row justify-content-end">
                        <h2 className="heading">{heading}</h2>
                    </div>
                );
            default:
                return null;
        }
    };

    if (isLoading) {
        return <div>Loading...</div>;
    }

    const firstName = props.contact ? (props.contact.FirstName || props.contact.firstName) : "Name";
    const lastName = props.contact ? (props.contact.LastName || props.contact.lastName) : "Last Name";
    const title = props.contact ? (props.contact.Title || props.contact.title) : "Title";
    const company = props.contact ? (props.contact.Company || props.contact.company) : "Company";

    if (!props.contact) {
        return <div>{translationLookup.NoInfoTxt}</div>;
    }

    return (
        <>
            <div className="container">
                <div className="section row">
                    <h2 className="intro-blk-copy"> {translationLookup.WelcomeTitleTxt}</h2>
                </div>

                <div className="row align-items-center profile-outer">
                    <div className="col-md-4 row align-items-center justify-content-around" style={{ borderRight: "1px solid black" }}>
                        <div className="profile-gravatar" style={{ backgroundImage: `url(${gravatarImg})` }}></div>
                        <div className="profile-container">
                            <p className="intro-blk-copy">{translationLookup.WelcomeTxt}</p>
                            <p className="section-title">{firstName} {lastName}</p>
                            <p className="intro-blk-copy">{title}</p>
                            <p className="intro-blk-copy">{company}</p>
                        </div>
                    </div>

                    {props.quizzes && props.quizzes.length ? (
                        <div className="col-md-4 justify-content-center" style={{ borderRight: "1px solid black" }}>
                            <ul className="row align-items-center justify-content-center">
                                {props.quizzes.slice(0, 2).map((item, index) => (
                                    <li key={index} className="col-md-3">
                                        <div className="profile-circle">
                                            <img src={item.quizInfo.badgeUrl} alt="quiz badge" />
                                        </div>
                                    </li>
                                ))}
                            </ul>
                            <div className="profile-container" style={{ textAlign: "center" }}>
                                <p className="intro-blk-copy">You've earned {props.quizzes.length} certificates</p>
                                <NavLink to={`/my-vollrath-university`}>{translationLookup.ViewAllTxt}</NavLink>
                            </div>
                        </div>
                    ) : (
                        <div className="col-md-4 justify-content-center" style={{ borderRight: "1px solid black" }}>
                            <div className="profile-container" style={{ textAlign: "center" }}>
                                <p className="intro-blk-copy">{translationLookup.Certificates0Txt}</p>
                                <NavLink to={`/my-vollrath-university`}>{translationLookup.ViewAllTxt}</NavLink>
                            </div>
                        </div>
                    )}

                    {props.events && props.events.length ? (
                        <div className="col-md-4 justify-content-center">
                            <ul className="row align-items-center justify-content-center">
                                {props.events.slice(0, 2).map((item, index) => (
                                    <li key={index} className="col-md-3">
                                        <div className="profile-circle">
                                            <img src={item.eventDetails.mainImage} alt="event badge" />
                                        </div>
                                    </li>
                                ))}
                            </ul>
                            <div className="profile-container" style={{ textAlign: "center" }}>
                                <p className="intro-blk-copy">You have {props.events.length} events coming up</p>
                                <NavLink to={`/my-vollrath-university`}>{translationLookup.ViewAllTxt}</NavLink>
                            </div>
                        </div>
                    ) : (
                        <div className="col-md-4 justify-content-center">
                            <div className="profile-container" style={{ textAlign: "center" }}>
                                <p className="intro-blk-copy">{translationLookup.Events0Txt}</p>
                                <NavLink to={`/my-vollrath-university`}>{translationLookup.ViewAllTxt}</NavLink>
                            </div>
                        </div>
                    )}
                </div>

               
            </div>

            <div className="section container">
                {props.filterableGrid.heading ? getFilterableGridHeading(props.filterableGrid.heading, props.filterableGrid.headingType) : null}
                {props.filterableGrid.api && (!props.filterableGrid.hideGrid ? (
                    <FilterableGrid {...props.filterableGrid} />
                ) : (
                    <div className="row justify-content-center">
                        <h3 className="section-title">Please check back soon...</h3>
                    </div>
                ))}
            </div>
        </>
    );
}

export default DashboardModules;
