import React, { useState, useEffect } from 'react';
import { useQueries } from '@tanstack/react-query';
import { getRecommendedProjectAttachments, getProject } from '../../../apis/project-builder';
import { useCurrentProjectIdContext } from "./project-builder-main.jsx";
import SearchMenuContainer from './search-menu.jsx';
import SearchModal from './search-modal.jsx';
import NewProjectModal from './new-project-modal.jsx';
import EditProjectModal from './edit-project-modal.jsx';
import DuplicateProjectModal from './duplicate-project-modal.jsx';
import DiscountModal from './discount-modal.jsx';
import ProjectSelector from './project-selector.jsx';
import ProductsList from './products-list.jsx';
import ProductsListByFamily from './products-list-by-family.jsx';
import AttachmentsList from './attachments-list.jsx';
import MoveModal from './move-modal.jsx';
import DeleteModal from './delete-modal.jsx';
import Breadcrumbs from './breadcrumbs.jsx';
import LoaderSection from '../../../../shared/react/components/loader-section.jsx';
import AddedAttachmentsList from './added-attachments-list.jsx';
import Sidebar from './sidebar.jsx';
import ProductsListHeader from './products-list-header.jsx';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import Tooltip from '@mui/material/Tooltip';
import CustomGroupModal from './custom-group-modal.jsx';
import MoveToGroupModal from './move-to-group-modal.jsx';



export default function ProjectDetail({ expandAll, setExpandAll }) {

    const { currentProjectId } = useCurrentProjectIdContext();

    const [recommendedProjectAttachmentsQuery, projectQuery] = useQueries({
        queries: [
            {
                queryKey: ['project-detail', currentProjectId, 'recommendedProjectAttachments'],
                queryFn: () => getRecommendedProjectAttachments(currentProjectId)
            },
            {
                queryKey: ['project-detail', currentProjectId],
                queryFn: () => getProject(currentProjectId)
            }
        ],
    });

    const [emptyGroups, setEmptyGroups] = useState([]);

    const [sortBy, setSortBy] = useState();

    const [showSelectedActions, setShowSelectedActions] = useState(false);
    const [selectedProductIds, setSelectedProductIds] = useState([]);
    const [selectedAttachmentIds, setSelectedAttachmentIds] = useState([]);


    const [isSearchOpen, setSearchIsOpen] = useState(false);
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const [isMoveOpen, setIsMoveOpen] = useState(false);
    const [isMoveToGroupOpen, setIsMoveToGroupOpen] = useState(false);

    const openSearchModal = () => setSearchIsOpen(true);
    const closeSearchModal = () => setSearchIsOpen(false);

    const openDeleteModal = () => setIsDeleteOpen(true);
    const closeDeleteModal = () => setIsDeleteOpen(false);

    const openMoveModal = () => setIsMoveOpen(true);
    const closeMoveModal = () => setIsMoveOpen(false);

    const openMoveToGroupModal = () => setIsMoveToGroupOpen(true);
    const closeMoveToGroupModal = () => setIsMoveToGroupOpen(false);

    const handleAddProduct = () => {
        closeSelectedActions();
        openSearchModal();
    }

    const handleSelectAllProducts = () => {
        let allProductIds = [];
        projectQuery.data.Products.forEach(product => allProductIds.push(product.Id));
        setSelectedProductIds(allProductIds);
    }

    const closeSelectedActions = () => {
        setShowSelectedActions(false);
        setSelectedProductIds([]);
        setSelectedAttachmentIds([]);
    }

    useEffect(() => {
        selectedProductIds.length || selectedAttachmentIds.length ? setShowSelectedActions(true) : setShowSelectedActions(false);
    }, [selectedProductIds, selectedAttachmentIds]);

    useEffect(() => {
        setShowSelectedActions(false);
        setSelectedProductIds([]);
        setSelectedAttachmentIds([]);
    }, [sortBy]);

    useEffect(() => {
        if (projectQuery.isSuccess) {
            setSortBy(projectQuery.data.SortType);
        }
    }, [projectQuery.data]);

    return (
        <>
            {projectQuery.isLoading && <LoaderSection show={true} /> }
            {projectQuery.isSuccess &&
                <div className="project-builder container section equal-margin project-builder-project-detail">
                    <SearchModal isSearchOpen={isSearchOpen} closeSearchModal={closeSearchModal} />
                    <Breadcrumbs />
                    {showSelectedActions &&
                        <div className="selected-actions">
                            <div className="container">
                                <div className="selected-actions-container">
                                    <button type="button" className="btn btn-outline-alt" onClick={openMoveModal}>
                                        move items ({selectedProductIds.length + selectedAttachmentIds.length})
                                        <MoveModal
                                            isMoveOpen={isMoveOpen}
                                            closeMoveModal={closeMoveModal}
                                            selectedProductIds={selectedProductIds}
                                            selectedAttachmentIds={selectedAttachmentIds}
                                            closeSelectedActions={closeSelectedActions} />
                                    </button>

                                    {selectedProductIds.length
                                        ? <DiscountModal
                                            productId={selectedProductIds}
                                            closeSelectedActions={closeSelectedActions}
                                        /> : null
                                    }

                                    <button type="button" className="btn no-arrow" onClick={openDeleteModal}>
                                        delete items ({selectedProductIds.length + selectedAttachmentIds.length})
                                    </button>
                                    <DeleteModal
                                        isDeleteOpen={isDeleteOpen}
                                        closeDeleteModal={closeDeleteModal}
                                        selectedProductIds={selectedProductIds}
                                        setSelectedProductIds={setSelectedProductIds}
                                        selectedAttachmentIds={selectedAttachmentIds}
                                        setSelectedAttachmentIds={setSelectedAttachmentIds}
                                    />

                                    {sortBy == "CustomGrouping" && selectedProductIds.length &&
                                        <button type="button" className="btn btn-outline-alt" onClick={openMoveToGroupModal}>Move Products to Custom Group ({selectedProductIds.length})
                                            <MoveToGroupModal
                                                isMoveToGroupOpen={isMoveToGroupOpen}
                                                closeMoveToGroupModal={closeMoveToGroupModal}
                                                selectedProductIds={selectedProductIds}
                                                setSelectedProductIds={setSelectedProductIds}
                                                closeSelectedActions={closeSelectedActions}
                                                emptyGroups={emptyGroups}
                                                setEmptyGroups={setEmptyGroups}
                                            />
                                        </button>
                                    }

                                    <button type="button" className="link-btn underline" onClick={closeSelectedActions}>CLOSE</button>
                                </div>
                            </div>
                        </div>
                    }

                    <div className="project-detail-header row align-items-center">
                        <div className="col-lg-3">
                            <ProjectSelector />
                        </div>

                        <div className="col-md-5">
                            <NewProjectModal
                                openButtonText="Create New Project"
                                buttonClass="link-btn underline"
                            />
                        </div>

                    </div>
                    <hr />
                    <div className="row align-items-center">
                        <div className="col-lg-8">
                            <span className="section-title">{projectQuery.data.Name}</span>
                            
                            <EditProjectModal
                                openButtonText="Edit Project Details"
                                buttonClass="link-btn mr-1"
                            />
                            <DuplicateProjectModal
                                openButtonText="Duplicate"
                                buttonClass="link-btn"
                            />
                            
                        </div>
                        <div class="col-lg-4">
                            <SearchMenuContainer />
                        </div>
                    </div>

                    <div className="row project-detail-content">
                        <div className="col-lg-8">
                            <div className="added-section added-products">
                                {(projectQuery.data.Products.length > 0) &&
                                    <ProductsListHeader
                                    expandAll={expandAll}
                                    setExpandAll={setExpandAll}
                                    handleAddProduct={handleAddProduct}
                                    handleSelectAllProducts={handleSelectAllProducts}
                                    />
                                }

                                {sortBy == "CustomGrouping" &&
                                    <>
                                        <div className="row">
                                            <div className="col-12">
                                                <CustomGroupModal
                                                buttonClass="btn no-arrow display-flex ml-auto mb-1"
                                                openButtonText="Add Custom Group"
                                                type="Add"
                                                emptyGroups={emptyGroups}
                                                setEmptyGroups={setEmptyGroups}
                                                />
                                            </div>
                                        </div>
                                        <ProductsListByFamily
                                        selectedProductIds={selectedProductIds}
                                        setSelectedProductIds={setSelectedProductIds}
                                        handleAddProduct={handleAddProduct}
                                        expandAll={expandAll}
                                        emptyGroups={emptyGroups}
                                        />
                                    </>
                                }

                                {sortBy == "ProductFamilyGrouping" &&
                                    <ProductsListByFamily
                                        selectedProductIds={selectedProductIds}
                                        setSelectedProductIds={setSelectedProductIds}
                                        handleAddProduct={handleAddProduct}
                                        expandAll={expandAll}
                                    />
                                }

                                {(sortBy == "Alphabetical" || sortBy == "DateAdded" || sortBy == "Sequence") &&
                                    <div className="row">
                                        <ProductsList
                                            selectedProductIds={selectedProductIds}
                                            setSelectedProductIds={setSelectedProductIds}
                                            handleAddProduct={handleAddProduct}
                                        />
                                    </div>
                                }
                            </div>

                            <AddedAttachmentsList
                                isSelectable
                                openSearchModal={openSearchModal}
                                selectedAttachmentIds={selectedAttachmentIds}
                                setSelectedAttachmentIds={setSelectedAttachmentIds}
                            />

                            {recommendedProjectAttachmentsQuery.isLoading && <LoaderSection show={true} /> }

                            {recommendedProjectAttachmentsQuery.isSuccess && recommendedProjectAttachmentsQuery.data.filter(attachment => attachment.IsDocument).length > 0 &&
                                <div className="added-section recommended-attachments">
                                    <div className="section-title">
                                        Recommended Document Attachments
                                        <Tooltip title="Document attachments that are recommended based on products that have been added to the project." placement="top">
                                            <HelpOutlineOutlinedIcon className="tooltip-help" />
                                        </Tooltip>
                                    </div>
                                    <div className="rounded-border">
                                        <AttachmentsList recommendedAttachments={recommendedProjectAttachmentsQuery.data?.filter(attachment => attachment.IsDocument)} collapsedText="Show All Recommended Documents" expandedText="Show Less Recommended" />
                                    </div>
                                </div>
                            }

                            {recommendedProjectAttachmentsQuery.isSuccess && recommendedProjectAttachmentsQuery.data.filter(attachment => attachment.IsVideo).length > 0 &&
                                <div className="added-section recommended-attachments">
                                    <div className="section-title">
                                        Recommended Video Attachments
                                        <Tooltip title="Video attachments that are recommended based on products that have been added to the project." placement="top">
                                            <HelpOutlineOutlinedIcon className="tooltip-help" />
                                        </Tooltip>
                                    </div>
                                    <div className="rounded-border">
                                        <AttachmentsList recommendedAttachments={recommendedProjectAttachmentsQuery.data?.filter(attachment => attachment.IsVideo)} collapsedText="Show All Recommended Videos" expandedText="Show Less Recommended" />
                                    </div>
                                </div>
                            }
                        </div>
                        <Sidebar />
                    </div>
                </div>
            }
        </>
    );
}