import React, { useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useCurrentProjectIdContext } from "./project-builder-main.jsx";
import { getProject } from '../../../apis/project-builder';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';


export default function ProductNotesList() {

    const { currentProjectId } = useCurrentProjectIdContext();

    const { data, isSuccess, isLoading } = useQuery(['project-detail', currentProjectId], () => getProject(currentProjectId));

    const [productsWithNotes, setProductsWithNotes] = useState([]);

    const [isExpanded, setIsExpanded] = useState(false);

    const toggleAccordion = () => {
        setIsExpanded((prev) => !prev);
    }

    useEffect(() => {
        if (isSuccess) {
            let newNotes = [];
            data.Products.forEach((product) => {
                product.Notes ? newNotes.push(product) : null;
            })
            setProductsWithNotes(newNotes);
        }
    }, [data])

    return (
        <>
            <ul className="product-notes-list pdf-view-only">
                {productsWithNotes.map((product, index) => (
                    <li key={index} className="product-notes-list-item">
                        <div className="product-notes-media">
                            <img src={product.ImageUrl} alt={product.Title} />
                        </div>
                        <div className="product-notes-content">
                            <div className="product-notes-title"><strong>{product.Sku}</strong> {product.Title}</div>
                            <div>{product.Notes}</div>
                        </div>
                    </li>
                ))}
            </ul>

            <ul className="product-notes-list hide-on-pdf">
                {productsWithNotes.slice(0, 4).map((product, index) => (
                    <li key={index} className="product-notes-list-item">
                        <div className="product-notes-media">
                            <img src={product.ImageUrl} alt={product.Title} />
                        </div>
                        <div className="product-notes-content">
                            <div className="product-notes-title"><strong>{product.Sku}</strong> {product.Title}</div>
                            <div>{product.Notes}</div>
                        </div>
                    </li>
                ))}
            </ul>

            {productsWithNotes.length > 4 &&
                <Accordion expanded={isExpanded} className="hide-on-pdf">
                    <AccordionSummary aria-controls="productNotes-content" id="productNotes-header" onClick={toggleAccordion}>
                        <div className="show-all mb-0">{isExpanded ? "Show Less Product Notes" : "Show All Product Notes" + " (" + productsWithNotes.length + ")"}</div>
                    </AccordionSummary>

                    <AccordionDetails>
                        <ul className="attachments-list attachments-list--paddingNone product-notes-list">
                            {productsWithNotes.slice(4).map((product, index) => (
                                <li key={index} className="product-notes-list-item">
                                    <div className="product-notes-media">
                                        <img src={product.ImageUrl} alt={product.Title} />
                                    </div>
                                    <div className="product-notes-content">
                                        <div className="product-notes-title"><strong>{product.Sku}</strong> {product.Title}</div>
                                        <div>{product.Notes}</div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </AccordionDetails>

                </Accordion>
            }
            
        </>
    );
}