import React, { useState, useEffect } from 'react';
import {
    QueryClient,
    QueryClientProvider,
} from "@tanstack/react-query";
//import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import QuickAdd from './quick-add.jsx';

export default function QuickAddWrapper({ item, index, videoNodeId, videoTitle }) {
    const queryClient = new QueryClient();

    const [authToken, setAuthToken] = useState({
        ExpiresAtUtc: null,
        Token: null
    });

    const [isTokenError, setIsTokenError] = useState(false);

    let clientId = 'pujadas.es';
    let clientSecret = 'be8a3056-5180-4cb9-b2e6-bf3cf303a0e0';


    const getToken = async () => {
        let apiUrl = '/api/ClientToken/Get?clientId=';
        await fetch(apiUrl + clientId + '&clientSecret=' + clientSecret)
            .then(response => {
                if (!response.ok) {
                    setIsTokenError(true)
                    setAuthToken({
                        ExpiresAtUtc: null,
                        Token: null
                    })
                };
                return response.json();
            })
            .then(data => {
                setAuthToken({
                    ExpiresAtUtc: data.expiresAtUtc,
                    Token: data.token
                });
                sessionStorage.setItem('authToken', JSON.stringify(data));
            });
    }

    useEffect(() => {
        let sessionToken = JSON.parse(sessionStorage.getItem('authToken'));
        if (!(sessionToken === null)) {
            if (typeof sessionToken.Token !== 'undefined') {
                let tokenExpirationDate = new Date(sessionToken.ExpiresAtUtc);
                let now = new Date();
                if (tokenExpirationDate < now) {
                    sessionStorage.removeItem('authToken');
                    getToken();
                }
                else {
                    setAuthToken({
                        ExpiresAtUtc: sessionToken.ExpiresAtUtc,
                        Token: sessionToken.Token
                    });
                }
            }
            else {
                getToken();
            }
        }
        else {
            getToken();
        }
    }, [])

    return (
        <QueryClientProvider client={queryClient}>
            {authToken.Token
                ? <QuickAdd item={item} index={index} videoNodeId={videoNodeId} videoTitle={videoTitle} />
                : <div className="container text-center">Getting Authorization Token...</div>
            }
        </QueryClientProvider>
    )
}