import React, { useState } from 'react';
//import { useCurrentProjectIdContext } from "./project-builder-main.jsx";
//import { useQuery } from '@tanstack/react-query';
//import { getProject } from '../../../components/project-builder-custom-hooks';
import DeleteModal from './delete-modal.jsx';
import Checkbox from '@mui/material/Checkbox';


export default function AddedAttachmentsCard({ isSelectable, attachment, selectedAttachmentIds, setSelectedAttachmentIds }) {

    //const { currentProjectId } = useCurrentProjectIdContext();

    const [isDeleteOpen, setIsDeleteOpen] = useState(false);

    const openDeleteModal = () => setIsDeleteOpen(true);
    const closeDeleteModal = () => setIsDeleteOpen(false);

    const handleCheckboxChange = (e) => {
        const isChecked = e.target.checked;
        if (isChecked) {
            setSelectedAttachmentIds(selectedAttachmentIds.concat(attachment.Id))
        } else {
            setSelectedAttachmentIds(selectedAttachmentIds.filter(x => x !== attachment.Id))
        }
    }

    return (
        <div key={attachment.Id} className="col-md-6 mb-2">
            <div className="attachment-card rounded-border">
                {isSelectable &&
                    <Checkbox
                        key={attachment.Id}
                        className="item-select"
                        onChange={(e) => handleCheckboxChange(e)}
                        checked={selectedAttachmentIds?.includes(attachment.Id)}
                        inputProps={{
                            'aria-label': 'Attachment',
                        }}
                    />
                }
                <div className="attachment-card-media" style={`background-image: url('${attachment.ImageUrl}')`}>
                    <div className="attachment-card-media-overlay"></div>
                </div>
                <div className="attachment-card-content">
                    <div className="attachment-card-sku">{attachment.Headline}</div>
                    <a href={attachment.LinkUrl} className="attachment-card-title" target="_blank">{attachment.Description}</a>
                </div>
                <button className="btn--delete" type="button" onClick={openDeleteModal}>
                    <span className="icon icon--trash"></span>
                    <DeleteModal isDeleteOpen={isDeleteOpen} closeDeleteModal={closeDeleteModal} attachment={attachment} />
                </button>
            </div>
        </div>
    )
}