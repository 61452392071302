import React, { Component, Suspense, lazy } from "react";
import ProductBase from './product-base.jsx';
import wishlist from '../../components/wishlist';
import toast from '../../components/toast';

class Product extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isInWishlist: false,
            isLoadingWishlist: false
        };

        this.handleWishlistClick = this.handleWishlistClick.bind(this);
    }

    isPujadas() {
        return window.location.host == ("localhost:8012") || window.location.hostname.includes("pujadas.es")
    }

    componentWillMount() {
        wishlist.hasProduct(this.props.skuNumber).then(result => this.setState({
            isInWishlist: result
        }));
    }

    handleWishlistClick(e) {
        wishlist.hasProduct(this.props.skuNumber).then(result => {
            this.setState({
                isInWishlist: result,
                isLoadingWishlist: true
            });
            if (result) {
                wishlist.delete(this.props.skuNumber)
                    .then(data => {
                        toast.success('Removed from your wish list');
                        this.setState({
                            isInWishlist: false
                        });
                    })
                    .catch(error => {
                        toast.error('There was an error trying to remove this product from your wish list');
                    })
                    .finally(() => {
                        this.setState({
                            isLoadingWishlist: false
                        });
                    });
            }
            else {
                wishlist.add(this.props.skuNumber)
                    .then(data => {
                        toast.success('Added to your wish list');
                        this.setState({
                            isInWishlist: true
                        });
                    })
                    .catch(error => {
                        console.log(error);
                        toast.error('There was an error trying to add this product to your wish list');
                    })
                    .finally(() => {
                        this.setState({
                            isLoadingWishlist: false
                        });
                    });
            }
        });
    }


    render() {
        let actions;
        
        let wishlistBtn =
            <button class="btn btn-outline-alt" type="button" onClick={this.handleWishlistClick} disabled={this
                .state.isLoadingWishlist}>
                <svg className="icon icon-heart"><use xlinkHref="#icon-heart"></use></svg>
                <span>Add to Wish List</span>
            </button>;


        if (this.state.isInWishlist) {
            wishlistBtn =
                <button class="btn btn-outline-alt" type="button" onClick={this.handleWishlistClick} disabled={this.state.isLoadingWishlist}>
                    <svg className="icon icon-heart-filled"><use xlinkHref="#icon-heart-filled"></use></svg>
                    <span>Added to Wish List</span>
                </button>;
        }

        let addToProjectBtn = "";

        if (window.location.host == ("localhost:44335")
            || this.isPujadas()
            || window.location.host == ("localhost:7012")
            || window.location.hostname.includes("vollrathfoodservice.com")
        ) {
            const QuickAddWrapper = lazy(() => import("../../../site-js/react/components/project-builder/quick-add-query-client.jsx"));
            addToProjectBtn = <Suspense><QuickAddWrapper item={this.props} /></Suspense>;
        }

        actions = <div class="full-card-actions">
            {this.props.showAddToProject || this.isPujadas() ? addToProjectBtn : wishlistBtn}
        </div>;

        return (
            <ProductBase {...this.props} actions={actions} />
        );
    }
}

export default Product;