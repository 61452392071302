import React from "react";
import {
    NavLink
} from "react-router-dom";


const SearchNav = props => {
    const activeColor = window.location.hostname.includes("stoelting") ? '#5D6677' : '#E10600';
    return (
        <nav className="row justify-content-center search-nav-container">
            <NavLink style={({ isActive }) => ({
                background: isActive ? activeColor : '#fff',
                color: isActive ? '#fff' : '#E10600'
            })} className="search-tab col-lg-2" to={`products?q=${props.q}`}>
                Products
            </NavLink>

            <NavLink style={({ isActive }) => ({
                background: isActive ? activeColor : '#fff',
                color: isActive ? '#fff' : '#E10600'
            })} className="search-tab col-lg-2" to={`documents?q=${props.q}`}>
                Documents
            </NavLink>

            {/*<NavLink style={({ isActive }) => ({*/}
            {/*    background: isActive ? activeColor : '#fff',*/}
            {/*    color: isActive ? '#fff' : '#1A458B'*/}
            {/*})} className="search-tab col-lg-2" to={`videos?q=${props.q}`}>*/}
            {/*    Videos*/}
            {/*</NavLink>*/}
        </nav>
    );
}

export default SearchNav;
