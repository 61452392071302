import React, { Component } from "react";
import { postGetTranslations } from '../../apis/translations';

class Filters extends Component {
    constructor(props) {
        super(props);
        this.state = {
            q: this.props.q,
            isFilterOpen: false,
            translationLookup: {}
        };

        this.onSearchInput = this.onSearchInput.bind(this);
        this.toggleFilter = this.toggleFilter.bind(this);

        let translationKeys = {
            SearchKeyword: 'Filters.Label.SearchKeyword',
            SearchKeywordPlaceholder: 'Filters.Label.SearchKeywordPlaceholder',
            FiltersText: 'Filters.Button.FiltersText',
        };

        postGetTranslations([
            translationKeys.SearchKeyword,
            translationKeys.SearchKeywordPlaceholder,
            translationKeys.FiltersText
        ]).then(translations => {
            this.setState({
                translationLookup: {
                    SearchKeyword: translations[translationKeys.SearchKeyword] ?? 'Search by keyword',
                    SearchKeywordPlaceholder: translations[translationKeys.SearchKeywordPlaceholder] ?? 'Narrow by keyword...',
                    FiltersText: translations[translationKeys.FiltersText] ?? 'Filters',
                }
            });
        }).catch(error => {
            console.log(error);
        });
    }

  

    componentDidUpdate(prevProps) {
        if (prevProps.q !== this.props.q) {
            this.setState({
                q: this.props.q
            });
        }
    }

    onSearchInput(e) {
        let value = '';
        if (e.target.value.trim()) {
            value = e.target.value;

        }
        this.setState({
            [e.target.name]: value
        });
        this.props.handleSearchChange(value);
    }

    toggleFilter() {
        this.setState({
            isFilterOpen: !this.state.isFilterOpen
        });
    }

    render() {
        let searchBox;


        if (this.props.showSearch !== false) {
            searchBox =
                <div className="filter-search">
                    <label className="sr-only" htmlFor="filter-search">{this.state.translationLookup.SearchKeyword}</label>
                    <input id="filter-search" type="text" name="q" placeholder={this.state.translationLookup.SearchKeywordPlaceholder} value={
                    this.state.q} onChange={this.onSearchInput} />
                </div>;
        }
        else if (this.props.filterMsg) {
            searchBox =
                <div className="no-search-box">
                    <p className="filter-msg">{this.props.filterMsg}</p>
                </div>;
        }

        return (
            <div className={`filter-expand ${this.state.isFilterOpen ? 'expanded' : ''}`}>
                <button className="filter-expand-btn" type="button" onClick={this.toggleFilter} aria-controls="filters" aria-expanded={this.state.isFilterOpen.toString()}>
                {this.state.translationLookup.FiltersText}
                    <span><svg class="icon icon-angle-up"><use xlinkHref="#icon-angle-up"></use></svg></span>
                </button>
                <div id="filters" className="filter">
                    {searchBox}
                    {this.props.children}
                </div>
            </div>
        );
    }
}

Filters.defaultProps = {
    q: '',
    handleSearchChange: function () { },
    filterMsg: 'Filter your search below...'
};

export default Filters;