import React, { useState, useEffect } from "react";
import LanguageSelector  from '../../../../shared/react/components/language-selector.jsx';
import { postGetTranslations } from '../../../../shared/apis/translations';
import { NavLink } from "react-router-dom";

const DashboardNav = (props) => {
    const [translationLookup, setTranslationLookup] = useState({});
    const translationKeys = {
        HomeTxt: 'Dashboard.Label.HomeTxt',
        ProjectBuilderTxt: 'Dashboard.Label.ProjectBuilderTxt',
        NewContentTxt: 'Dashboard.Label.NewContentTxt',
        MyProfileTxt: 'Dashboard.Label.MyProfileTxt',
        LogoutTxt: 'Dashboard.Label.LogoutTxt',
        YourAccountTxt: 'Dashboard.Label.YourAccountTxt',
        HiTxt: 'Dashboard.Label.HiTxt',
    };

    useEffect(() => {
        postGetTranslations(Object.values(translationKeys))
            .then((translations) => {
                setTranslationLookup({
                    HomeTxt: translations[translationKeys.HomeTxt] ?? 'Homepage',
                    ProjectBuilderTxt: translations[translationKeys.ProjectBuilderTxt] ?? 'Project Builder',
                    NewContentTxt: translations[translationKeys.NewContentTxt] ?? 'New Content',
                    MyProfileTxt: translations[translationKeys.MyProfileTxt] ?? 'My Profile',
                    LogoutTxt: translations[translationKeys.LogoutTxt] ?? 'Logout',
                    YourAccountTxt: translations[translationKeys.YourAccountTxt] ?? 'Your Account',
                    HiTxt: translations[translationKeys.HiTxt] ?? 'Hi',
                });
            })
            .catch((error) => {
                console.error('Error fetching translations:', error);
            });
    }, []);

    const getLogoHref = () => {
        const path = window.location.pathname;
        if (path.startsWith('/es')) {
            return '/es';
        } else if (path.startsWith('/fr')) {
            return '/fr';
        }
        return '/';
    };

    return (
        <div className="header-primary-wrapper">
            <div className="header-primary portal">
                <div className="container">
                    <div className="header-logo-col">
                        <a className="header-logo campaign" href={getLogoHref()}>
                            <img src="/img/logo.png" width="136" alt="" />
                            <span className="sr-only">Pujadas</span>
                        </a>
                    </div>

                    <nav className="header-secondary-second-col header-nav" aria-label="Utility">
                        <ul className="header-secondary-ul js-secondary-nav">
                            <li className="header-secondary-li">
                                <NavLink className="portal" to={`/`}>
                                    {translationLookup.HomeTxt}
                                </NavLink>
                            </li>
                            <li className="header-secondary-li">
                                <NavLink className="portal" to={`/project-builder`}>
                                    {translationLookup.ProjectBuilderTxt}
                                </NavLink>
                            </li>
                            <li className="header-secondary-li">
                                <NavLink className="portal" to={`/notifications`}>
                                    {translationLookup.NewContentTxt} ({props.notifications?.length || 0})
                                </NavLink>
                            </li>
                        </ul>
                    </nav>
                    <ul className="header-secondary-third-col js-account-options header-nav">
                        <li className="header-secondary-li">
                            <a className="has-dropdown account-heading add-account-margin">
                                <span className="account-name">{translationLookup.HiTxt}, {props.contact?.FirstName || 'Guest'}</span>
                                <span
                                    className=""
                                    dangerouslySetInnerHTML={{ __html: translationLookup.YourAccountTxt }}
                                ></span>
                            </a>

                            <ul className="menu-dropdown small">
                                <li>
                                    <NavLink to={`/profile`}>
                                        {translationLookup.MyProfileTxt}
                                    </NavLink>
                                    <a className="logout-nav js-logout">
                                        {translationLookup.LogoutTxt}
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <LanguageSelector />
                </div>
            </div>
        </div>
    );
};

export default DashboardNav;
