import React, { Component } from "react";
import Lazyload from './lazyload.jsx';
import CSSTransition from 'react-transition-group/CSSTransition';
import { productFallbackImage } from '../../config.json';

class ProductBase extends Component {
    constructor(props) {
        super(props);

        this.state = {
            imageVisible: false
        };


        this.handleLazyloadVisible = this.handleLazyloadVisible.bind(this);

        this.thumbnailWithFallback = this.thumbnailWithFallback.bind(this);

        this.formatPrice = this.formatPrice.bind(this);
        this.formatSpecHighlights = this.formatSpecHighlights.bind(this);
    }

    handleLazyloadVisible() {
        this.setState({
            imageVisible: true
        });
    }

    thumbnailWithFallback() {
        if (this.props.thumbnail) {
            return <img srcSet={`${this.props.thumbnail} 1x, ${this.props.thumbnailHighRes} 2x`} alt={this.props.documentName} />;
        }
        else {
            return <img srcSet={productFallbackImage} alt={this.props.documentName} />;
        }
    }

    formatPrice() {
        if (this.props.skuPrice > 0 && FeaturesEnabled.ShowProductPrice && !msrp || this.props.skuPrice > 0 && this.props.isFinalPrice && !this.props.hideDiscontinuedPrice) {
            const price = this.props.isFinalPrice && this.props.inventoryQuantity > 0
                ? this.props.skuPriceFormatted
                : this.props.listPriceFormatted;
            return (
                <li className="attribute-info">
                    <div className="attribute-info-text">
                        <span className="attribute-info-trail">{this.props.isFinalPrice && this.props.inventoryQuantity > 0 ? 'Final price' : 'List price'}</span>
                        <span className="attribute-info-trait">{price}</span>
                    </div>
                </li>);
        } else if (msrp) {
            const price = this.props.msrp;
            return (
                <li className="attribute-info">
                    <div className="attribute-info-text">
                        <span className="attribute-info-trail">MSRP</span>
                        <span className="attribute-info-trait">{price}</span>
                    </div>
                </li>);
        }
    }

    formatDealerPrice() {
        return (
            <li className="attribute-info">
                <div className="attribute-info-text">
                    <span className="attribute-info-trait">{this.props.dealerPriceFormatted}</span>
                </div>
            </li>
        );
    }

    formatSpecHighlights() {
        if (this.props.specHighlight1 || this.props.specHighlight2) {
            const specHighlightStrings = [];
            if (this.props.specHighlight1) specHighlightStrings.push(this.props.specHighlight1.split(':')[1]);
            if (this.props.specHighlight2) specHighlightStrings.push(this.props.specHighlight2.split(':')[1]);
            return <p className="full-card-attr">{specHighlightStrings.join(', ')}</p>;
        }
    }

    render() {
        const filteredFeatures = this.props.productCardFeatures.filter(f => f.split(':').length > 1);
        const discontinued = this.props.discontinued && !this.props.userType || this.props.isFinalPrice && this.props.inventoryQuantity < 1 ? 'discontinued-product' : '';
        let actions = <></>;
        if (this.props.discontinued && !this.props.userType) {
            if (this.props.productReplacementURL)
                actions = <div class="full-card-actions"><a href={this.props.productReplacementURL} className="btn btn-outline-alt" ><span>View New Replacement Product</span></a></div>
        } else {
            actions = this.props.actions;
        }

        const discontinuedCTA = this.props.discontinued
            ? <span className="full-card-discontinued-cta">Discontinued</span>
            : <React.Fragment></React.Fragment>;

        return (
            <div className={this.props.colClass}>
                <article className={`full-card ${discontinued}`}>
                    <div className="full-card-media">
                        <CSSTransition in={this.state.imageVisible} timeout={250} classNames="fade">
                            <Lazyload height="220px" rootMargin="100px" onVisible={this.handleLazyloadVisible}>
                                <a href={this.props.pageUrl} tabIndex="-1">{this.thumbnailWithFallback()}</a>
                            </Lazyload>
                        </CSSTransition>
                    </div>
                    <div className="full-card-info">
                        <div className="full-card-subtitle-content">
                            <p className="full-card-subtitle">{this.props.skuNumber} </p>
                            {discontinuedCTA}
                        </div>
                        <h3 className="full-card-title"><a href={this.props.pageUrl}>{this.props.productName}</a></h3>
                        {this.formatSpecHighlights()}
                        <ul className="full-card-attributes">
                            {filteredFeatures.map((feature, index) => (
                                <li className="attribute-info">
                                    <div className="attribute-info-text">
                                        <span className="attribute-info-trail">{feature.split(':')[0]}</span>
                                        <span className="attribute-info-trait">{feature.split(':')[1]}</span>
                                    </div>
                                </li>
                            ))}

                            {/*Always assume the the override price, the region price, or the list price will exist in the dealerPrice field*/}
                            {this.props.useDealerPrice &&
                                <>
                                { this.props.dealerPrice == null && <span>Log in to see price</span> }    
                                { this.props.dealerPrice != null && this.formatDealerPrice() }    
                                </>
                            }
                            
                            {!this.props.useDealerPrice && this.formatPrice()}
                        </ul>
                        {actions}
                    </div>
                </article>
            </div>
        );
    }
}

ProductBase.defaultProps = {
    colClass: 'col-lg-4 col-6 item-col',
    userType: false
};

export default ProductBase;