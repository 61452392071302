import React, { useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getProject } from '../../../apis/project-builder';
import { useCurrentProjectIdContext } from "./project-builder-main.jsx";
import ProductCard from './product-card.jsx';
import LoaderSection from '../../../../shared/react/components/loader-section.jsx';

export default function ProductsList({ reviewList, readOnly, selectedProductIds, setSelectedProductIds, handleAddProduct }) {

    const { currentProjectId } = useCurrentProjectIdContext();

    const { data, isSuccess, isLoading, isError, error } = useQuery(['project-detail', currentProjectId], () => getProject(currentProjectId));

    if (data.SortType == "Alphabetical") {
        //sort by A-Z
        data.Products.sort((a, b) => {
            let titleA = a.Title.toLowerCase();
            let titleB = b.Title.toLowerCase();
            if (titleA < titleB) { return -1; }
            if (titleA > titleB) { return 1; }
            return 0;
        })
    }
    else if (data.SortType == "DateAdded") {
        //sort by newest added
        data.Products.sort((a, b) => {
            if (a.CreatedAt > b.CreatedAt) { return -1; }
            if (a.CreatedAt < b.CreatedAt) { return 1; }
            return 0;
        })
    }
    else if (data.SortType == "Sequence") {
        //sort by sort order field
        data.Products.sort((a, b) => {
            if (a.SortOrder < b.SortOrder) { return -1; }
            if (a.SortOrder > b.SortOrder) { return 1; }
            return 0;
        })
    }

    let cardCount = 0;

    const getColSpan = () => {
        cardCount = cardCount + 1;
        return (cardCount % 3 === 0 || reviewList) ? "12" : "6";
    }

    const isFullWidth = () => {
        return (reviewList ? true : (cardCount % 3 === 0) ? true : false);
    }

    return (
        <>
            {isLoading && <LoaderSection show={true} />}
            {isError && <h3>There was an error loading the products</h3>}

            {isSuccess &&
                <>
                    {data.Products.map((product, index) => (
                        <>
                            <div class={`col-md-${getColSpan()} ${reviewList ? "" : "mb-2"}`}>
                                <ProductCard
                                    isSelectable={!reviewList}
                                    isFullWidth={isFullWidth()}
                                    reviewList={reviewList}
                                    readOnly={readOnly}
                                    key={product.Id}
                                    product={product}
                                    selectedProductIds={selectedProductIds}
                                    setSelectedProductIds={setSelectedProductIds}
                                    sortType={data.SortType}
                                />
                            </div>
                            {product.Accessories.map((accessory, accessoryIndex) => (
                                <div class={`col-md-${getColSpan()} ${reviewList ? "" : "mb-2"}`}>
                                    <ProductCard
                                        isSelectable={!reviewList}
                                        isFullWidth={isFullWidth()}
                                        reviewList={reviewList}
                                        readOnly={readOnly}
                                        key={accessory.Id}
                                        product={accessory}
                                        parentId={product.Sku}
                                        selectedProductIds={selectedProductIds}
                                        setSelectedProductIds={setSelectedProductIds}
                                        sortType={data.SortType}
                                        isAccessory
                                    />
                                </div>
                            ))}
                        </>
                    ))}

                    {!reviewList && !readOnly ? (
                        <div className={`col-md-${getColSpan()} mb-2`}>
                            <div className="product-card product-card--add rounded-border" onClick={handleAddProduct}>
                                <button className="btn-add" type="button"><span className="plus-icon">+</span></button>
                                <div className="cta-title">Add Product</div>
                            </div>
                        </div>
                    ) : null}
                </>
            }
        </>
    );
}