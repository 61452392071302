import React, { useState, useEffect } from 'react';
//import { useCurrentProjectIdContext } from "./project-builder-main.jsx";
//import { getProject } from '../../../apis/project-builder';
//import { useQuery } from '@tanstack/react-query';
//import Loader from '../../../../shared/react/components/loader.jsx';
import AddRemoveProductsButton from './add-remove-products-button.jsx';


export default function AccessoriesListItem({ isAdded, accessory, parentProductId, readOnly }) {

    //const { currentProjectId } = useCurrentProjectIdContext();

    //const { data, isSuccess } = useQuery(['project-detail', currentProjectId], () => getProject(currentProjectId));

    return (
        <li key={accessory.ProductNodeId} className={`${isAdded ? "isAdded" : ""}`}>

            {readOnly
                ? <div className="attachment-title">
                    <span>{accessory.Sku}: </span> {accessory.Title}
                </div>
                :<div className="attachment-title">
                    <span>{accessory.ItemNumber}: </span> {accessory.ProductName}
                </div>
            }

            {readOnly
                ? null
                : isAdded ? null : <AddRemoveProductsButton productNodeId={accessory.ProductNodeId} parentProductId={parentProductId} />
            }

        </li >
    );
}