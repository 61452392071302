import React, { useState } from 'react';
import { useCurrentProjectIdContext } from "./project-builder-main.jsx";
import { useQuery } from '@tanstack/react-query';
import { getProject } from '../../../apis/project-builder';
import DeleteModal from './delete-modal.jsx';
import Checkbox from '@mui/material/Checkbox';
import AddedAttachmentsCard from './added-attachments-card.jsx';
//import Loader from '../../../../shared/react/components/loader.jsx';


export default function AddedAttachmentsList({ isSelectable, openSearchModal, selectedAttachmentIds, setSelectedAttachmentIds }) {

    const { currentProjectId } = useCurrentProjectIdContext();

    const { data, isSuccess, isLoading, isError, error } = useQuery(['project-detail', currentProjectId], () => getProject(currentProjectId));


    return (
        <>
            {isSuccess
                ? <div className="project-section added-attachments">
                    <div className="section-title">Attachments</div>
                    <div className="section-subtitle">Documents</div>
                    <div className="row">
                        {data.Attachements?.filter(attachment => attachment.IsDocument).map((attachment) => (
                            <AddedAttachmentsCard
                                isSelectable={isSelectable}
                                attachment={attachment}
                                selectedAttachmentIds={selectedAttachmentIds}
                                setSelectedAttachmentIds={setSelectedAttachmentIds}
                            />
                        ))}

                        <div className="col-md-6 mb-2">
                            <div className="attachment-card attachment-card--add rounded-border" onClick={openSearchModal}>
                                <button className="btn-add btn-add--small" type="button"><span className="plus-icon plus-icon--small">+</span></button>
                                <div className="cta-title">Search for Documents</div>
                            </div>
                        </div>
                    </div>

                    {/*<div className="section-subtitle">Videos</div>*/}
                    {/*<div className="row">*/}
                    {/*    {data.Attachements?.filter(attachment => attachment.IsVideo).map((attachment) => (*/}
                    {/*        <AddedAttachmentsCard*/}
                    {/*            isSelectable={isSelectable}*/}
                    {/*            attachment={attachment}*/}
                    {/*            selectedAttachmentIds={selectedAttachmentIds}*/}
                    {/*            setSelectedAttachmentIds={setSelectedAttachmentIds}*/}
                    {/*        />*/}
                    {/*    ))}*/}

                    {/*    <div className="col-md-6 mb-2">*/}
                    {/*        <div className="attachment-card attachment-card--add rounded-border" onClick={openSearchModal}>*/}
                    {/*            <button className="btn-add btn-add--small" type="button"><span className="plus-icon plus-icon--small">+</span></button>*/}
                    {/*            <div className="cta-title">Search for Videos</div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
                : null
            }
        </>
    );
}