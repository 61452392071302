import React, { useEffect, useState } from 'react';
import {
    Route,
    BrowserRouter as Router,
    Routes,
    Navigate
} from "react-router-dom";
import DashboardProfile from './DashboardProfile.jsx';
import DashboardNav from './DashboardNav.jsx';
import DashboardModules from './DashboardModules.jsx';
import DashboardModule from './DashboardModule.jsx';
import DashboardVU from './DashboardVU.jsx';
import DashboardCode from './DashboardCode.jsx';
import DashboardEventCodes from './DashboardEventCodes.jsx';
import DashboardNotifications from './DashboardNotifications.jsx';
import DashboardEvents from './DashboardEvents.jsx';
import ProjectBuilderMain from '../project-builder/project-builder-main.jsx';

import { updateCurrentContact, getQuizzesForContact, getEventsForContact, updateModuleOrder, exportData } from '../../../../shared/apis/account';
import { getSalesReps } from '../../../../shared/apis/dealers';
import { getApi } from '../../../../shared/components/filterable-grid.js';
import toast from '../../../../shared/components/toast';

// Helper function to detect the locale from the URL
const getLocaleFromPath = () => {
    const path = window.location.pathname;
    const match = path.match(/^\/(\w{2})\//); // This regex assumes the locale is always two letters
    return match ? `/${match[1]}` : '';
};

const App = props => {

    const [reps, setReps] = useState([]);
    const [quizzes, setQuizzes] = useState([]);
    const [events, setEvents] = useState([]);
    const [modules, setModules] = useState([]);
    const [notifications, setNotifications] = useState([]);
    const [filterableGrid, setFilterableGrid] = useState({});
    const [contact, setContact] = useState({});
    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
        setModules(JSON.parse(props.portalModules));
        setContact(JSON.parse(props.contact));
        setNotifications(JSON.parse(props.notifications));

        const fg = JSON.parse(props.filterableGrid);
        fg.nodeId = fg.parentNodeId;
        fg.categories = fg.filterCategories;
        fg.bigCards = fg.useBigCards;
        fg.children = fg.childCount;
        fg.cardType = fg.useProductCards;
        fg.api = getApi(fg.apiType);

        setFilterableGrid(fg);
    }, []);

    const locale = getLocaleFromPath();
    const basename = `${locale}/dashboard`;

    const updateContact = (params) => {

        if (window.confirm('Are you sure you want to update your profile?')) {
            updateCurrentContact(params)
                .then(data => {
                    if (data.success) {
                        toast.success('Your profile has been updated successfully');
                        setContact(data.data);
                    } else {
                        data.messages.map((item) => {
                            toast.error(item, 10000);
                        });
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        }

    }

    const exportEventData = (params) => {
        if (window.confirm('Are you sure you want to export this event?')) {
            exportData(params)
                .then((data) => {

                    var fileName = `event_export_${new Date().toISOString().slice(0, 10)}.csv`;

                    const blob = new Blob([data], { type: "text/csv" });
                    const blobURL = window.URL.createObjectURL(blob);

                    let a = document.createElement('a');
                    a.href = blobURL;
                    a.target = '_blank';
                    a.download = fileName;
                    a.click();
                    a.remove();
                    return false;
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }

    const getRepsByZipcode = (params) => {

        getSalesReps(params)
            .then(data => {
                setReps(data);
            })
            .catch(error => {
                console.log(error);
            });
    }

    const getQuizzes = (params) => {
        getQuizzesForContact(params)
            .then(data => {
                if (data.success) {
                    setQuizzes(data.data.quizResults);
                }
            })
            .catch(error => {
                console.log(error);
                data.Messages.map((item) => {
                    toast.error(item, 10000);
                });
            });
    }

    const getEvents = (params) => {
        getEventsForContact(params)
            .then(data => {
                setEvents(data.filter(c => new Date(Date.parse(c.eventDetails.startDate)).getTime() > new Date().getTime()))
            })
            .catch(error => {
                console.log(error);

            });
    }

    const updateOrder = (params) => {
        updateModuleOrder(params)
            .then(data => {
                setModules(data);
                setIsEditing(!isEditing);
            })
            .catch(error => {
                console.log(error);

            });
    }

    return (
        <div>
            <Router basename={basename}>
                <DashboardNav contact={contact} notifications={notifications} role={props.role} />
                <Routes>
                    <Route path="/profile" element={<DashboardProfile contact={contact} updateContact={updateContact} />} />
                    <Route path="/module" element={<DashboardModule />} />
                    <Route path="/eventcode" element={<DashboardCode />} />
                    {/*<Route path="/eventcodes" element={<DashboardEventCodes exportEventData={exportEventData} contact={contact} />} />*/}
                    {/*<Route path="/my-events" element={<DashboardEvents />} />*/}
                    <Route path="/project-builder/*" element={<ProjectBuilderMain />} />
                    <Route path="/notifications" element={<DashboardNotifications notifications={notifications} />} />

                    <Route path="/"
                        element={
                            <DashboardModules
                                role={props.role}
                                contact={contact}
                                modules={modules}
                                notifications={notifications}
                                filterableGrid={filterableGrid}
                                getQuizzesForContact={getQuizzes}
                                quizzes={quizzes}
                                getEventsForContact={getEvents}
                                events={events}
                                updateOrder={updateOrder}
                                isEditing={isEditing}
                                setIsEditing={setIsEditing} />
                        } />


                    {/*<Route path="/my-team" element={<DashboardTeam contact={contact} getRepsByZipcode={getRepsByZipcode} reps={reps} />} />*/}
                    {/*<Route path="/my-vollrath-university" element={<DashboardVU contact={contact} getQuizzesForContact={getQuizzes}*/}
                    {/*    quizzes={quizzes} getEventsForContact={getEvents} events={events} />} />*/}

                </Routes>
            </Router>

        </div>
    );
}

App.defaultProps = {
    contact: {}
};

export default App;