import React, { useState, useEffect } from 'react';

import PDFView from './PDFView.jsx';
import Tabs from './Tabs.jsx';
import Selections from './Selections.jsx';

import Step1 from './Step1.jsx';
import Step2 from './Step2.jsx';
import Step3 from './Step3.jsx';
import Step4 from './Step4.jsx';
import Step5 from './Step5.jsx';

function App() {

    let printView = false;
    if (window.location.href.includes("?currency=")) {
        printView = true;

        var body = document.getElementsByTagName("body")[0];
        body.classList.add("lsc-printView");
    }

    const [currentStep, setCurrentStep] = useState(2);
    const [currency, setCurrency] = useState("0");
    const [dispenserType, setDispenserType] = useState("");
    const [selectedProduct, setSelectedProduct] = useState({});
    const [selectedLayout, setSelectedLayout] = useState({});
    const [selectedComponents, setSelectedComponents] = useState([]);
    const [authToken, setAuthToken] = useState({
        ExpiresAtUtc: '',
        Token: ''
    });

    const [isTokenError, setIsTokenError] = useState(false);

    let clientId = '';
    let clientSecret = '';


    const getToken = async () => {
        let apiUrl = '';
        clientId === 'vollrathfoodservice.com' ? apiUrl = '/api/ClientToken/Get?clientId=' : apiUrl = 'https://www.vollrathfoodservice.com/api/ClientToken/Get?clientId=';
        await fetch(apiUrl + clientId + '&clientSecret=' + clientSecret)
            .then(response => {
                if (!response.ok) setIsTokenError(true);
                return response.json();
            })
            .then(data => {
                setAuthToken({
                    ExpiresAtUtc: data.expiresAtUtc,
                    Token: data.token
                });
                sessionStorage.setItem('authToken', JSON.stringify(data));
            });
    }

    useEffect(() => {
        clientId = document.getElementById('lid-saver-configurator').getAttribute('data-clientId');
        clientSecret = document.getElementById('lid-saver-configurator').getAttribute('data-clientSecret');

        let sessionToken = JSON.parse(sessionStorage.getItem('authToken'));
        if (!(sessionToken === null)) {
            if (typeof sessionToken.Token !== 'undefined') {
                let tokenExpirationDate = new Date(sessionToken.ExpiresAtUtc);
                let now = new Date();
                if (tokenExpirationDate < now) {
                    sessionStorage.removeItem('authToken');
                    getToken();
                }
                else {
                    setAuthToken({
                        ExpiresAtUtc: sessionToken.ExpiresAtUtc,
                        Token: sessionToken.Token
                    });
                }
            }
            else {
                getToken();
            }
        }
        else {
            getToken();
        }

    }, []);


    const [errorMessage, setErrorMessage] = useState({
        message: "",
        visible: false
    });

    useEffect(() => {
        setErrorMessage({
            message: "",
            visible: false
        });
    }, [selectedProduct, selectedLayout, selectedComponents]);

    //Change Handlers
    const handleProductChange = (event, products, productIndex) => {
        let productName = event.target.value;
        setSelectedProduct(products[productIndex]);
        setDispenserType(productName);
        setSelectedLayout({});
    }

    const handleLayoutChange = (layout) => {
        setSelectedLayout(layout);
        setSelectedComponents([]);
    }

    //Step Handlers
    const goBack = () => {
        setErrorMessage({
            message: "",
            visible: false
        })
        setCurrentStep(currentStep - 1);
    }

    const reset = () => {
        setCurrentStep(2);
        setCurrency("0");
        setDispenserType("");
        setSelectedProduct({});
        setSelectedLayout({});
        setSelectedComponents();
        setErrorMessage({
            message: "",
            visible: false
        });
    }

    const goForward = () => {
        //check for errors
        if (currentStep === 2 && Object.keys(selectedProduct).length === 0) {
            setErrorMessage({
                message: "ERROR: You must select a dispenser type option",
                visible: true
            });
            return;
        };
        if (currentStep === 3 && Object.keys(selectedLayout).length === 0) {
            setErrorMessage({
                message: "ERROR: You must select a dispenser layout option",
                visible: true
            });
            return;
        };
        if (currentStep === 4 && selectedComponents.length < parseInt(selectedLayout.code.charAt(0))) {
            setErrorMessage({
                message: "ERROR: You must select a option for each module",
                visible: true
            });
            return;
        };
        // This rule only applies to regular Cabinet 18 and 23 inch
        if (currentStep === 4 && (selectedProduct.id === "S18-000" || selectedProduct.id === "S23-000")) {
            let hasLidOrCup = false;
            selectedComponents.forEach(component => {
                if (component.moduleCode === "C" || component.moduleCode === "L") {
                    hasLidOrCup = true;
                }
            });
            if (!hasLidOrCup) {
                setErrorMessage({
                    message: "ERROR: all cabinets must have at least one LID or CUP module",
                    visible: true
                });
                return;
            }
        }

        // This rule only applies to steel cabinets
        if (currentStep === 4 && selectedProduct.code === "5882") {
            let numberOfModules = selectedComponents.length
            for (let i = 0; i < numberOfModules - 1; i++) {
                if (selectedComponents[i].code === "S") {
                    setErrorMessage({
                        message: "ERROR: STRAWS must only be in the BOTTOM position for Steel Cabinets",
                        visible: true
                    });
                    return;
                }
            }
        };

        setCurrentStep(currentStep + 1);
    }

    return (
        <div className="App">
            {!printView &&
                <Tabs currentStep={currentStep} />
            }
            {currentStep > 1 && !printView &&
                <Selections
                    currency={currency}
                    dispenserType={dispenserType}
                    selectedLayout={selectedLayout}
                />
            }
            {currentStep === 1 && !printView &&
                <Step1
                    currency={currency}
                    onChange={(value) => setCurrency(value)}
                />
            }
            {currentStep === 2 && !printView && authToken &&
                <Step2
                    isTokenError={isTokenError}
                    authToken={authToken}
                    dispenserType={dispenserType}
                    onChange={handleProductChange}
                />
            }
            {currentStep === 3 && !printView &&
                <Step3
                    selectedProduct={selectedProduct}
                    selectedLayout={selectedLayout}
                    onChange={handleLayoutChange}
                />
            }
            {currentStep === 4 && !printView &&
                <Step4
                    selectedLayout={selectedLayout}
                    moduleOptions={selectedProduct.modules}
                    selectedComponents={selectedComponents}
                    setSelectedComponents={setSelectedComponents}
                />
            }
            {currentStep === 5 && !printView &&
                <Step5
                    authToken={authToken}
                    currency={currency}
                    dispenserType={dispenserType}
                    selectedProduct={selectedProduct}
                    selectedLayout={selectedLayout}
                    selectedComponents={selectedComponents}
                />
            }
            {!printView &&
                <>
                    <div id="stepErrorContainer">
                        {errorMessage.visible === true ? <div id="stepError">{errorMessage.message}</div> : ""}
                    </div>

                    <div className="lidSaverControls">
                        {currentStep > 2 && <a className="btn no-arrow btn-back" id="back" onClick={() => goBack()}>Back</a>}
                        {currentStep > 1 && !isTokenError && <div id="reset" onClick={() => reset()}>Reset</div>}
                        {currentStep < 5 && !isTokenError && <a className="btn" id="continue" onClick={() => goForward()}>Continue</a>}
                    </div>
                </>
            }
            {printView &&
                <PDFView />
            }
        </div>
    );
}

export default App;