import React, { useState, useEffect } from 'react'

export default function Step5({ authToken, currency, dispenserType, selectedProduct, selectedLayout, selectedComponents }) {

	const [price, setPrice] = useState();
	const [formattedPrice, setFormattedPrice] = useState("");
	const [itemNumber, setItemNumber] = useState("");
	const [pdfIsLoading, setPdfIsLoading] = useState(false);

	let layoutOrientation = selectedLayout.displayName.split(" ")[1];
	let moduleCodes = [];
	selectedComponents.forEach(item => moduleCodes.push(item.Code));

	//Product number and price API call
	const Currency = currency;
	const BaseProduct = {
		"id": selectedProduct.id,
		"DisplayName": selectedProduct.displayName,
		"Code": selectedProduct.code,
		"LidSaverIdentifier": selectedProduct.lidSaverIdentifier
	}
	const Layout = selectedLayout;
	const Components = [...selectedComponents];


	const results = { Currency, BaseProduct, Layout, Components };
	const requestOptions = {
		method: 'POST',
		headers: {
			'Authorization': 'Bearer ' + authToken.Token,
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(results)
	};

	let dealerClientId = '';
	let apiUrl = '';
	dealerClientId = document.getElementById('lid-saver-configurator').getAttribute('data-clientId');
	dealerClientId === 'vollrathfoodservice.com' ? apiUrl = '/api/LidSaverDispenserConfiguration/Create' : apiUrl = 'https://www.vollrathfoodservice.com/api/LidSaverDispenserConfiguration/Create';

	useEffect(() => {
		fetch(apiUrl, requestOptions)
			.then(response => response.json())
			.then(data => {
				setItemNumber(data.configuredPartNumber);
				if (currency === "0") {
					setPrice(data.price.toFixed(2));
					setFormattedPrice("$" + data.price.toFixed(2) + " (USD)");
				}
				else {
					setFormattedPrice(String.fromCharCode(8364) + data.price.toFixed(2) + " (EUR)");
				}
			});
	}, []);

	//results json variable and product callback for dealer module
	useEffect(() => {
		if (dealerClientId !== 'vollrathfoodservice.com') {
			let lidsaverResults = { itemNumber: itemNumber, price: price };
			if (lidsaverResults.itemNumber.length > 0) {
				window.lidsaverResultsJSON = JSON.stringify(lidsaverResults);

				//dealer callback to get productCode and price
				if (typeof window.lidsaverResultsCallback === 'function') {
					window.lidsaverResultsCallback(itemNumber, price);
				}
			}
		}
	}, [price]);

	// PDF View Generation
	let url = window.location.href;

	var domainUrl = "";

	if (url.includes("vollrathfoodservice")) {
		//use dev or staging or production url for vollrath site
		domainUrl = url.split("/")[0] + "//" + url.split("/")[2];
	}
	else {
		//use production site url for dealer module
		domainUrl = "https://www.vollrathfoodservice.com"
	}

	//let pdfUrl = "https://www.vollrathfoodservice.com/lidsaver-3/dispenser-builder"

	let pdfUrl = domainUrl + "/lidsaver-3/dispenser-builder"
		+ "?currency=" + currency
		+ "&dispensertype=" + dispenserType.replaceAll(" ", "_")
		+ "&layout=" + selectedLayout.displayName.replaceAll(" ", "_")
		+ "&itemnumber=" + itemNumber
		+ "&price=" + formattedPrice.replaceAll(" ", "_")
		+ "&modulecodes=" + moduleCodes
		+ "&dealerClientId=" + dealerClientId;

	//let encodedPdfUrl = "https://www.vollrathfoodservice.com/api/pdf/getpdf?url=" + encodeURIComponent(pdfUrl);
	let encodedPdfUrl = domainUrl + "/api/pdf/getpdf?url=" + encodeURIComponent(pdfUrl);

	const generatePdf = (e) => {
		e.preventDefault();
		setPdfIsLoading(true);

		fetch(encodedPdfUrl, {
			headers: {
				'Authorization': 'Bearer ' + authToken.Token
			}
		}).then(x => x.blob())
			.then(x => {
				let a = document.createElement('a');
				a.href = URL.createObjectURL(x);
				a.setAttribute('target', '_blank');
				a.setAttribute('download', 'VollrathLidSaverQuote');
				a.click();
				a.remove();
				setPdfIsLoading(false);
			});

	}

	/// Product code and price logic is now being done on the back end
	// Build module parent type codes
	/*
	let parentTypeCodes = [...selectedModuleCodes];
	parentTypeCodes.forEach(function (item, index, parentCodes) {
		switch (item) {
			case "S":
				parentCodes[index] = "S";
				break;
			case "P":
				parentCodes[index] = "P";
				break;
			case "A":
				parentCodes[index] = "C";
				break;
			case "D":
				parentCodes[index] = "C";
				break;
			default:
				parentCodes[index] = "L";
				break;
		}
	});
	*/

	/*
	//Build module codes
	let formattedParentTypeCodes = "";
	let formattedModuleCodes = "";

	if (selectedLayout.Code.includes("V")) {
		//Vertical layouts read from bottom to top
		formattedParentTypeCodes = parentTypeCodes.toString().replaceAll(",", "").split("").reverse().join("");
		formattedModuleCodes = selectedModuleCodes.toString().replaceAll(",", "-").split("-").reverse().join("-");
	}
	else if (selectedLayout.Code.includes("X")) {
		//Square layouts read from bottom to top and left to right
		let squareParentTypeCodes = [];
		let squareSelectedModuleCodes = [];
		squareParentTypeCodes[0] = parentTypeCodes[2];
		squareParentTypeCodes[1] = parentTypeCodes[0];
		squareParentTypeCodes[2] = parentTypeCodes[3];
		squareParentTypeCodes[3] = parentTypeCodes[1];

		squareSelectedModuleCodes[0] = selectedModuleCodes[2];
		squareSelectedModuleCodes[1] = selectedModuleCodes[0];
		squareSelectedModuleCodes[2] = selectedModuleCodes[3];
		squareSelectedModuleCodes[3] = selectedModuleCodes[1];

		formattedParentTypeCodes = squareParentTypeCodes.toString().replaceAll(",", "");
		formattedModuleCodes = squareSelectedModuleCodes.toString().replaceAll(",", "-");
	}
	else {
		formattedParentTypeCodes = parentTypeCodes.toString().replaceAll(",", "");
		formattedModuleCodes = selectedModuleCodes.toString().replaceAll(",", "-");
	}

	if (formattedParentTypeCodes === "LLLL") {
		formattedParentTypeCodes = "4L";
	};

	// Build full item #
	let itemNumber = selectedProduct.Code + selectedLayout.Code + "-" + formattedParentTypeCodes + "-" + formattedModuleCodes;

	// Build price
	let price = selectedLayout.PriceUsd;
	let parentTypeArray = [];

	parentTypeCodes.forEach(item => {
		let result = selectedProduct.Modules.filter(obj => {
			return obj.Code === item
		})
		parentTypeArray.push(result);
	});

	parentTypeArray.forEach(function (item, index) {
		let result = item[0].Components.filter(obj => {
			return obj.Code === selectedModuleCodes[index]
		})
		price = price + result[0].PriceUsd;
	});

	price = price.toFixed(2);
	*/

	/*
	async function getEuroPrice() {
		await fetch('https://api.exchangeratesapi.io/latest?symbols=USD')
			.then(response => response.json())
			.then(data => setFormattedPrice(String.fromCharCode(8364) + (price / data.rates.USD).toFixed(2) + " (EUR)"))
			.catch(error => {
				console.error('There has been a problem with your fetch operation:', error);
				setFormattedPrice("$" + price + " USD (Contact Vollrath for Euro pricing)");
			});
	}

	useEffect(() => {
		if (currency === "0") {
			setFormattedPrice("$" + price + " (USD)");
		}
		else {
			getEuroPrice();
		}
	}, [])
	*/

	//send result to BE for pdf generation
	/*
	useEffect(() => {
		const results = { itemNumber, price };
		const requestOptions = {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(results)
		};

		fetch('https://jsonplaceholder.typicode.com/todos/3', requestOptions)
			.then(response => response.json())
		//.then(data => console.log(data.id));
	}, []);
	*/

	return (
		<div className="lidSaverContainer lidSaverContainerStep5">
			<div className="stepHeader">
				<h2>Your Results</h2>
			</div>
			<p>Your item number and total list price are shown below. Click below to generate a PDF of your results that you can save, email, or print and contact your nearest Vollrath sales representative for more information.</p>

			<div className={`modulesContainer results ${layoutOrientation}`}>
				{selectedComponents.map((item, i) => (
					<div key={i} className="layoutModule bigSquare">{item.code}</div>
				))}
			</div>
			<h3>Your Complete Item #: {itemNumber}</h3>
			<h3>Total List Price: {formattedPrice}</h3>
			{!pdfIsLoading
				? <a class="btn" href="" onClick={generatePdf} target="_blank">Generate PDF</a>
				: <div className="loader-section"><div className="loader-spinner"></div></div>
			}
		</div>
	)
}
