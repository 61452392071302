import React, { useState, useEffect } from 'react';
import Magnific from '../../../../shared/react/components/magnific.jsx';

export default function GlassInfoSize({ type, setSearchBySize, setInfoSelected, sizeInfo, setSizeInfo }) {

    const [errors, setErrors] = useState({
        maxDia: true,
        maxDiaWithHandle: true,
        maxHeight: true
    });

    var fractionRegEx = /^\d+\s\d{1,2}\/\d{1,2}$/g;
    var decimalRegEx = /^\d+(\.\d+)?$/g;

    const handleValidation = (name, value) => {
        if (value.match(fractionRegEx) || value.match(decimalRegEx)) {
            setErrors(prevState => ({
                ...prevState,
                [name]: false
            }));
        }
        else {
            setErrors(prevState => ({
                ...prevState,
                [name]: true
            }));
        }
    }

    const handleSizeInfoChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        setSizeInfo(prevState => ({
            ...prevState,
            [name]: value
        }));

        handleValidation(name, value);
    }

    const handleScaleChange = (e) => {
        let value = e.target.value;
        let scaleType = 1;
        if (value === "cm") { scaleType = 2 };
        setSizeInfo(prevState => ({
            ...prevState,
            scale: scaleType
        }));
    }

    //how to measure magnific modal
    const [showHowToMeasure, setShowHowToMeasure] = useState(false);

    const howToMeasureSrc = '/Vollrath/media/images/MeasurementGuideFinalV2.jpg';
    const howToMeasureItems = [{
        type: "image",
        src: howToMeasureSrc,
        description: "Glass Measurement Guide",

    }];

    const handleHowToMeasureOpen = (e) => {
        e.preventDefault();
        setShowHowToMeasure(true);
    }

    const handleHowToMeasureClose = () => {
        setShowHowToMeasure(false);
    }

    //fraction conversion chart modal
    const [showFractionChart, setShowFractionChart] = useState(false);

    const fractionChartSrc = '/Vollrath/media/images/FractionConversionChart.jpg';
    const fractionChartItems = [{
        type: "image",
        src: fractionChartSrc,
        description: "Fraction Conversion Chart",

    }];

    const handleFractionChartOpen = (e) => {
        e.preventDefault();
        setShowFractionChart(true);
    }

    const handleFractionChartClose = () => {
        setShowFractionChart(false);
    }
    // -end modal

    useEffect(() => {
        if (type === 1) {
            !errors.maxDia && !errors.maxHeight ? setInfoSelected(true) : setInfoSelected(false)
        }
        else {
            !errors.maxDia && !errors.maxHeight && !errors.maxDiaWithHandle ? setInfoSelected(true) : setInfoSelected(false);
        }

    }, [sizeInfo, type]);

    return (
        <div className="step-content no-pad-top">
            <div className="step-options step-options--column">
                {type === 2 &&
                    <>
                    <label className="control-label" for="maxDiaWithHandle">Max. Diameter with handle*</label>
                    <input className={`form-control ${errors.maxDiaWithHandle ? "highlight" : ""}`} value={sizeInfo.maxDiaWithHandle} type="text" name="maxDiaWithHandle" onChange={event => handleSizeInfoChange(event)} maxLength="8" />
                    </>
                }
                <label className="control-label" for="maxDia">Max. Diameter without handle*</label>
                <input className={`form-control ${errors.maxDia ? "highlight" : ""}`} value={sizeInfo.maxDia} type="text" name="maxDia" onChange={event => handleSizeInfoChange(event)} maxLength="8" />
                <label className="control-label" for="maxHeight">Max. Height*</label>
                <input className={`form-control ${errors.maxHeight ? "highlight" : ""}`} value={sizeInfo.maxHeight} type="text" name="maxHeight" onChange={event => handleSizeInfoChange(event)} maxLength="8" />
                <div className="options-container">
                    <label>
                        <input type="radio" name="scale" value="cm" checked={sizeInfo.scale === 2} onChange={event => handleScaleChange(event)} />
                        cm.
                    </label>
                    <label>
                        <input type="radio" name="scale" value="in" checked={sizeInfo.scale === 1} onChange={event => handleScaleChange(event)} />
                        in.
                    </label>
                </div>
                <div className="helpers">
                    <a id="how-to-measure" href="" onClick={handleHowToMeasureOpen}>How to Measure</a>
                    <a id="fraction-chart" href="" onClick={handleFractionChartOpen}>Fraction Conversion Chart</a>
                </div>
            </div>
            <Magnific items={howToMeasureItems} show={showHowToMeasure} onClose={handleHowToMeasureClose} />
            <Magnific items={fractionChartItems} show={showFractionChart} onClose={handleFractionChartClose} />
        </div>
    )
}