import React, { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";
import CustomContentItem from '../../../../shared/react/components/custom-content-item.jsx';
import animatedScrollTo from 'animated-scroll-to';
import { postGetTranslations } from '../../../../shared/apis/translations';

const DashboardNotifications = (props) => {

    const [translationLookup, setTranslationLookup] = useState({});
    const translationKeys = {
        ContentTitleTxt: 'Dashboard.Label.ContentTitleTxt',
    };

    useEffect(() => {
        postGetTranslations(Object.values(translationKeys))
            .then((translations) => {
                setTranslationLookup({
                    ContentTitleTxt: translations[translationKeys.ContentTitleTxt] ?? 'Your New Portal Content Text',
                });
            })
            .catch((error) => {
                console.error('Error fetching translations:', error);
            });
    }, []);

    useEffect(() => {
        animatedScrollTo(document.querySelector('#module-top'));
    }, [])

    return (
        <>
            {props.notifications && props.notifications.length ?
               
                <div id="module-top" className="container">
                    <div className="row section">
                        <div className="col-md-12 add-margin-btm-extra">
                            <NavLink to={`/`}>
                                Return
                            </NavLink>
                        </div>
                        <div className="col-md-12">
                            <div className="section-title section-header profile">{translationLookup.ContentTitleTxt} </div>
                            <div className="row">
                                {props.notifications.map((page, index) => (
                                    <CustomContentItem key={index} {...page} colClass="col-md-3" cardType={false} />
                                ))}
                            </div>
                        </div>
                    </div>
                </div> :
                 <div id="module-top" className="container">
                    <div className="row section">
                        <div className="col-md-12 add-margin-btm-extra">
                            <NavLink to={`/`}>
                                Return
                            </NavLink>
                        </div>
                        <div className="col-md-12">
                            <div className="section-title section-header profile">{translationLookup.ContentTitleTxt} </div>
                            <p>There is no new content to show at this time.</p>
                        </div>
                    </div>
                </div> 
            }


        </>
    );
}

export default DashboardNotifications;