import React, { useState, useEffect } from 'react'
import Product from './Product.jsx'

export default function ProductGroups({ authToken, dispenserType, onChange }) {

	const [productGroups, setProductGroups] = useState([]);
	const [productGroupsIsLoading, setProductGroupIsLoading] = useState(false);

	let clientId = '';

	const getProductGroups = () => {
		setProductGroupIsLoading(true);
		let apiUrl = '';
		clientId = document.getElementById('lid-saver-configurator').getAttribute('data-clientId');
		clientId === 'vollrathfoodservice.com' ? apiUrl = '/api/LidSaverDispenserConfiguration/GetProductGroups' : apiUrl = 'https://www.vollrathfoodservice.com/api/LidSaverDispenserConfiguration/GetProductGroups';
		return fetch(apiUrl, {
			method: 'GET',
			headers: {
				'Authorization': 'Bearer ' + authToken.Token,
				'Content-Type': 'application/json'
			}
		})
			.then(response => response.json())
			.then(data => {
				setProductGroups(data);
				setProductGroupIsLoading(false);
			});
	}

	useEffect(() => {
		if (authToken.Token.length > 0) {
			getProductGroups();
		}
	}, [authToken]);


	return (
		<>
			{productGroupsIsLoading
				? <div className="loader-section">
					<div className="loader-spinner"></div>
				</div>
				: productGroups.map((productGroup, i) => (
					<div
						key={i}
						className="dispenserContainer">
						<div className="dispenserContainerInner">
							<h4>{productGroup.displayName}</h4>
							<img
								src={productGroup.image}
								alt={productGroup.imageAlt}
							/>
						</div>
						<Product
							productGroup={productGroup}
							onChange={onChange}
							dispenserType={dispenserType}
						/>
					</div>
				))
			}
		</>
	)
}
