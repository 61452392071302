import React, { useState, useEffect } from 'react';
import { useCurrentProjectIdContext } from "./project-builder-main.jsx";
import { getProject } from '../../../apis/project-builder';
import { useQuery } from '@tanstack/react-query';
//import { useAddAttachment } from '../../../components/project-builder-custom-hooks';
//import Loader from '../../../../shared/react/components/loader.jsx';
import AddRemoveDocumentsButton from './add-remove-documents-button.jsx';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';


export default function AttachmentsListItem({ attachment, deleteOnly, readOnly }) {

    const isDocument = attachment.IsDocument;
    //const isVideo = attachment.IsVideo;

    const { currentProjectId } = useCurrentProjectIdContext();

    const { data, isSuccess } = useQuery(['project-detail', currentProjectId], () => getProject(currentProjectId));

    const [isAdded, setIsAdded] = useState(false);

    const checkIfAdded = () => {
        if (isDocument) {
            data.Attachements.some(attachmentItem => attachmentItem.DocumentLocalSlug === attachment.DocumentLocalSlug) ? setIsAdded(true) : setIsAdded(false);
            return;
        }

        data.Attachements.some(attachmentItem => attachmentItem.VideoNodeId === attachment.VideoNodeId) ? setIsAdded(true) : setIsAdded(false);
    };

    checkIfAdded();
    useEffect(() => {
        //checkIfAdded();
    }, [])

    return (
        <>
            {isSuccess
            ? <li key={attachment.Id} className={`${isAdded ? "isAdded" : ""}`}>
                    {isDocument ? (
                        <div className="icon-container">
                            <svg class="icon icon-document-file-pdf">
                                <use href="#icon-document-file-pdf"></use>
                            </svg>
                        </div>
                    ) : (
                        <div className="icon-container icon-container--video">
                            {/*<svg class="icon icon-play card-media-play">*/}
                            {/*    <use href="#icon-play"></use>*/}
                                {/*</svg>*/}
                                <PlayCircleOutlineIcon />
                        </div>
                    )}
                    <a href={attachment.LinkUrl} target="_blank" className="attachment-title">
                        <span>{attachment.Headline}: </span> {attachment.Description}
                    </a>

                {readOnly
                    ? null
                        : <AddRemoveDocumentsButton itemSlug={attachment.DocumentLocalSlug} videoNodeId={attachment.VideoNodeId} deleteOnly={deleteOnly} isAdded={isAdded} setIsAdded={setIsAdded} />
                }

            </li >
            : null
            }
        </>
    );
}