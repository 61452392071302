import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useCurrentProjectIdContext } from "./project-builder-main.jsx";
import { getProjects } from '../../../apis/project-builder';
import Select from 'react-select';

export default function ProjectSelector() {

	const { currentProjectId, setCurrentProjectId } = useCurrentProjectIdContext();

	const navigate = useNavigate();

	const { data, isSuccess, isLoading, isError, error } = useQuery(['projects-list'], getProjects);

	const [options, setOptions] = useState([]);

	//let options = [];

	const handleChange = (value) => {
		setCurrentProjectId(value.value);
		navigate(`/project-builder/${value.value}`)
	};

	useEffect(() => {
		if (isSuccess) {
			let newOptions = [];
			data.forEach((item) => {
				let id = item.Id;
				let name = item.Name;
				let productCount = item.Products.length;
				newOptions.push({
					value: id,
					label: name + " (" + productCount + ")"
				});
			});
			setOptions(newOptions);
        }
    }, [data])

	return (
		<>
			{isSuccess
				? <>
					<label className="sr-only">Select Project</label>
					<Select
						classNamePrefix="styled-select"
						options={options}
						value={options.find(({ value }) => value === currentProjectId)}
						onChange={handleChange}
					/>
				</>
				: null
			}
		</>
	);
}