import React, { useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getProject } from '../../../apis/project-builder';
import { useCurrentProjectIdContext } from "./project-builder-main.jsx";
import ProductsList from './products-list.jsx';
import ProductsListByFamily from './products-list-by-family.jsx';
import ProductsListHeader from './products-list-header.jsx';
import ProductNotesList from './product-notes-list.jsx';
import AttachmentsList from './attachments-list.jsx';
import animatedScrollTo from 'animated-scroll-to';
import Sidebar from './sidebar.jsx';
import DownloadsButtons from './downloads-buttons.jsx';


export default function ReceivedQuote({ expandAll, setExpandAll }) {

    const { currentProjectId } = useCurrentProjectIdContext();

    const { data, isSuccess, isLoading } = useQuery(['project-detail', currentProjectId], () => getProject(currentProjectId));

    const handleSectionLinkClick = (e) => {
        let href = e.target.getAttribute("href");
        let section = document.querySelector(href);
        animatedScrollTo(section);
    }

    return (
        <div className="project-builder project-builder-received-quote">
            {isSuccess ?
                <>
                    <div className="container received-quote-header">
                        <div className="downloads-container">
                            <div className='section-title'>{data.name}</div>
                            <div className="hide-mobile ml-auto">
                                <DownloadsButtons guid={data.guid} projectName={data.name} projectId={data.id} isMarketingProject={data.isMarketingProject} />
                            </div>
                        </div>
                        <div className="section-links">
                            <ul>
                                <li><a href="#quote-information" onClick={handleSectionLinkClick}>Information</a></li>
                                <li><a href="#quote-products" onClick={handleSectionLinkClick}>Products</a></li>
                                <li><a href="#quote-attachments" onClick={handleSectionLinkClick}>Attachments</a></li>
                                <li><a href="#quote-project-notes" onClick={handleSectionLinkClick}>Project Notes</a></li>
                                <li><a href="#quote-product-notes" onClick={handleSectionLinkClick}>Product Notes</a></li>
                            </ul>
                        </div>

                        <div className="mobile-downloads-container">
                            <hr />
                            <DownloadsButtons guid={data.guid} projectName={data.name} projectId={data.id} isMarketingProject={data.isMarketingProject} />
                        </div>
                    </div >
                    <hr />
                    <div className="container section equal-margin">
                        <div className="row review-quote-content">
                            <div className="col-lg-8">
                                <div id="quote-information" className="added-section added-information">
                                    <div className="section-header">
                                        <div className="section-number">1</div>
                                        <div className="section-title">Information</div>
                                        <div className="quote-details">
                                            <ul>

                                                <li>Account Name: <span>{data.accountName}</span></li>
                                                <li>Zip Code: <span>{data.zipCode}</span></li>
                                                <li>Created By: <span>{data.createdBy}</span></li>
                                                <li>Contact Email Address: <span>{data.contactEmailAddress}</span></li>
                                                <li>Contact Phone Number: <span>{data.contactPhoneNumber}</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div id="quote-project-header" className="added-section added-information">
                                    <div className="section-header section-header--borderTop">
                                        <div className="section-number">2</div>
                                        <div className="section-title">Project Header</div>
                                    </div>
                                    <div className="rounded-border rounded-border--paddingSmall">
                                        <p>{data.headerText}</p>
                                    </div>
                                </div>

                                <div id="quote-products" className="added-section added-products">
                                    <div className="section-header">
                                        <div className="section-number">3</div>
                                        <div className="section-title mr-auto">Products</div>
                                        {(data.products.length > 0) &&
                                            <ProductsListHeader
                                                expandAll={expandAll}
                                                setExpandAll={setExpandAll}
                                                reviewList
                                            />
                                        }
                                    </div>

                                    {(data.sortType == "ProductFamilyGrouping" || data.sortType == "CustomGrouping")
                                        ? <div className="rounded-border rounded-border--noPadding"><ProductsListByFamily expandAll={expandAll} reviewList readOnly /></div>
                                        : <div className="rounded-border"><ProductsList reviewList readOnly /></div>
                                    }
                                </div>

                                <div id="quote-attachments" className="added-section added-attachments">
                                    <div className="section-header">
                                        <div className="section-number">4</div>
                                        <div className="section-title">Attachments</div>
                                    </div>
                                    <div className="section-subtitle">Documents</div>
                                    <div className="rounded-border mb-1">
                                        <AttachmentsList
                                            readOnly
                                            collapsedText="Show All Documents"
                                            expandedText="Show Less Documents"
                                            recommendedAttachments={data.attachements.filter((attachment) => attachment.isDocument)}
                                        />
                                    </div>

                                    <div className="section-subtitle">Videos</div>
                                    <div className="rounded-border rounded-border--paddingSmall">
                                        <AttachmentsList
                                            readOnly
                                            collapsedText="Show All Videos"
                                            expandedText="Show Less Videos"
                                            recommendedAttachments={data.attachements.filter((attachment) => attachment.isVideo)}
                                        />
                                    </div>
                                </div>

                                <div id="quote-project-notes" className="added-section added-information">
                                    <div className="section-header">
                                        <div className="section-number">5</div>
                                        <div className="section-title">Project Notes</div>
                                    </div>
                                    <div className="rounded-border rounded-border--paddingSmall">
                                        <p>{data.notes}</p>
                                    </div>
                                </div>

                                <div id="quote-product-notes" className="added-section added-information">
                                    <div className="section-header">
                                        <div className="section-number">6</div>
                                        <div className="section-title">Product Notes</div>
                                    </div>
                                    <div className="rounded-border rounded-border--paddingSmall">
                                        <ProductNotesList />
                                    </div>
                                </div>




                                {data.isMarketingProject ?
                                    null :
                                    <>
                                        <div className="quote-price condensed-view-only"><span>PRODUCT TOTAL:</span> ${data.totalProductPrice.toFixed(2)}</div>
                                        <div className="rounded-border mt-2">
                                            <div className="send-quote-card">
                                                <div className="send-quote-card-content ml-0">
                                                    <div className="quote-price"><span>QUOTE PRICE:</span> ${data.totalNetPrice.toFixed(2)}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }

                            </div>

                            <Sidebar received />
                        </div>

                        <div id="signature-section">
                            <h2>Thank you for choosing Pujadas!</h2>
                            <br />
                            <div className="contact-information">
                                <p>{data.salesRep?.firstName} {data.salesRep?.lastName}</p>
                                <p>{data.salesRep?.position}</p>
                                <p>{data.salesRep?.phoneNumber}</p>
                                {data.salesRep?.email &&
                                    <p>
                                        <a href={`mailto:${data.salesRep.email}`}>{data.salesRep.email}</a>
                                    </p>
                                }
                            </div>
                            <br />
                            <br />
                            <div id="signature-footer">
                                <div>
                                    <span>Acceptance:___________________________________</span> <span>Date:____________________</span>
                                </div>
                                <br /><br />
                                <div>Printed Name:__________________________________</div>
                            </div>
                        </div>

                        <div id="footer-signature-section">
                            <div>
                                <div className="title" style={{ fontWeight: 'bold', color:'#666666'}}>{data.name}</div>
                                <div>Created By: {data.createdBy}</div>
                                <div>{data.accountName}</div>
                                <div>{data.contactEmailAddress}</div>
                                <div>{data.contactPhoneNumber}</div>
                            </div>
                        </div>

                    </div>
                </>
                : null
            }
        </div >
    );
}