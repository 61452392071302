import React, { useState } from 'react';
import Loader from '../../../../shared/react/components/loader.jsx';

export default function DownloadsButtons({ guid, projectName, projectId, isMarketingProject }) {

    const [quoteIsLoading, setQuoteIsLoading] = useState(false);
    const [condensedQuoteIsLoading, setCondensedQuoteIsLoading] = useState(false);
    const [sheetIsLoading, setSheetIsLoading] = useState(false);
    const [csvIsLoading, setCsvIsLoading] = useState(false);

    const apiHeader = () => {
        let authToken = JSON.parse(sessionStorage.getItem('authToken'));
        let header = {
            'Authorization': 'Bearer ' + authToken.Token
        }
        return header;
    }

    const url = window.location.href;

    //const csvUrl = url.split("/")[0] + "//" + url.split("/")[2] + "/api/projectBuilder/projects/" + projectId + "/csv";

    const handleDownloadCsv = () => {
        setCsvIsLoading(true);

        let csvUrl = url.split("/")[0] + "//" + url.split("/")[2] + "/api/projectBuilder/projects/" + projectId + "/csv";

        fetch(csvUrl, {
            headers: apiHeader()
        }).then(x => x.blob())
            .then(x => {
                let a = document.createElement('a');
                a.href = URL.createObjectURL(x);
                a.setAttribute('target', '_blank');
                a.setAttribute('download', 'ProjectBuider_' + projectId + "_" + projectName);
                a.click();
                a.remove();
                setCsvIsLoading(false);
            });
    }

    const handleDownloadQuote = () => {
        setQuoteIsLoading(true);

        let pdfUrl = url.split("/")[0] + "//" + url.split("/")[2] + "/api/projectBuilder/projects/" + projectId + "/Pdf";

        fetch(pdfUrl, {
            headers: apiHeader()
        }).then(x => x.blob())
            .then(x => {
                let a = document.createElement('a');
                a.href = URL.createObjectURL(x);
                a.setAttribute('target', '_blank');
                a.setAttribute('download', projectName + ' Project Quote');
                a.click();
                a.remove();
                setQuoteIsLoading(false);
            });
    }

    const handleDownloadCondensedQuote = () => {
        setCondensedQuoteIsLoading(true);

        let pdfUrl = url.split("/")[0] + "//" + url.split("/")[2] + "/api/projectBuilder/projects/" + projectId + "/condensedpdf";

        fetch(pdfUrl, {
            headers: apiHeader()
        }).then(x => x.blob())
            .then(x => {
                let a = document.createElement('a');
                a.href = URL.createObjectURL(x);
                a.setAttribute('target', '_blank');
                a.setAttribute('download', projectName + ' Condensed Project Quote');
                a.click();
                a.remove();
                setCondensedQuoteIsLoading(false);
            });
    }

    const handleDownloadSellSheet = () => {
        setSheetIsLoading(true);

        let sheetUrl = url.split("/")[0] + "//" + url.split("/")[2] + "/api/projectBuilder/projects/" + projectId + "/specSheetPdf";

        fetch(sheetUrl, {
            headers: apiHeader()
        }).then(x => x.blob())
            .then(x => {
                let a = document.createElement('a');
                a.href = URL.createObjectURL(x);
                a.setAttribute('target', '_blank');
                a.setAttribute('download', projectName + ' Sell Sheets');
                a.click();
                a.remove();
                setSheetIsLoading(false);
            });

    }
    

    return (
        <div className="quote-downloads">
        {isMarketingProject
            ? sheetIsLoading
                ? <Loader show={true} />
                : <button type="button" className="link-btn" onClick={handleDownloadSellSheet}><span className="icon icon--download"></span>SELL SHEETS</button>
        : <>
            {quoteIsLoading
                ? <Loader show={true} />
                : <button type="button" className="link-btn" onClick={handleDownloadQuote}><span className="icon icon--download"></span>PDF QUOTE</button>
            }

            {condensedQuoteIsLoading
                ? <Loader show={true} />
                : <button type="button" className="link-btn" onClick={handleDownloadCondensedQuote}><span className="icon icon--download"></span>CONDENSED PDF QUOTE</button>
            }

            {csvIsLoading
                ? <Loader show={true} />
                : <button type="button" className="link-btn" onClick={handleDownloadCsv}><span className="icon icon--download"></span>CSV QUOTE</button>
            }

            {/*<a href={csvUrl} className="link-btn" download><span className="icon icon--download"></span>CSV QUOTE</a>*/}

            {sheetIsLoading
                ? <Loader show={true} />
                : <button type="button" className="link-btn" onClick={handleDownloadSellSheet}><span className="icon icon--download"></span>SELL SHEETS</button>
            }
        </>
        }
        </div>
    );
}