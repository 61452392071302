import React, { useState, useEffect } from 'react';
import { useLocation, NavLink, useNavigate } from "react-router-dom";
import { updateEventData, sendNotificationEmail } from '../../../../shared/apis/account';
import toast from '../../../../shared/components/toast';
const DashboardModule = (props) => {

    const location = useLocation();

    const [codeData, setCodeData] = useState({});
    const startDate = new Date(Date.parse(location.state.data.eventDetails.startDate)).toLocaleDateString('en-us', { weekday: "long", year: "numeric", month: "short", day: "numeric" });
    const endDate = new Date(Date.parse(location.state.data.eventDetails.endDate)).toLocaleDateString('en-us', { weekday: "long", year: "numeric", month: "short", day: "numeric" });
    const closedDate = new Date(Date.parse(location.state.data.closedDate)).toLocaleDateString('en-us', { weekday: "long", year: "numeric", month: "short", day: "numeric" });
    const navigate = useNavigate();
    const [isEditing, setIsEditing] = useState(false);
    const [isDsmEditing, setIsDsmEditing] = useState(false);
    const [isRsmEditing, setIsRsmEditing] = useState(false);
    const [isDsrEditing, setIsDsrEditing] = useState(false);
    const [editClass, setEditClass] = useState('');
    const [dsmClass, setDsmClass] = useState('disabled-control');
    const [rsmClass, setRsmClass] = useState('disabled-control');
    const [dsrClass, setDsrClass] = useState('disabled-control');

    useEffect(() => {
        setCodeData({
            codeId: location.state.data.codeId,
            dsm: location.state.data.dsm,
            rsm: location.state.data.rsm,
            dsr: location.state.data.dsr,
            hotelRoom: location.state.data.hotelRoom,
            arrivalCarService: location.state.data.arrivalCarService,
            departureCarService: location.state.data.departureCarService,
            rentalCar: location.state.data.rentalCar,
            firstName: location.state.data.firstName,
            lastName: location.state.data.lastName,
            email: location.state.data.email,
            phoneNumber: location.state.data.phoneNumber,
            address: location.state.data.address,
            city: location.state.data.city,
            state: location.state.data.state,
            postalCode: location.state.data.postalCode,
            vollrathDealer: location.state.data.vollrathDealer,
            jobFunction: location.state.data.jobFunction,
            jobTitle: location.state.data.jobTitle,
            over21: location.state.data.over21,
            accomodations: location.state.data.accomodations,
            dietaryNeeds: location.state.data.dietaryNeeds,
            badgeName: location.state.data.badgeName,
            emergencyContactName: location.state.data.emergencyContactName,
            emergencyContactPhoneNumber: location.state.data.emergencyContactPhoneNumber,
            travelMethod: location.state.data.travelMethod,
            arrivalAirline: location.state.data.arrivalAirline,
            arrivalFlightNumber: location.state.data.arrivalFlightNumber,
            arrivalDate: location.state.data.arrivalDate,
            arrivalTime: location.state.data.arrivalTime,
            departureAirline: location.state.data.departureAirline,
            departureFlightNumber: location.state.data.departureFlightNumber,
            departureDate: location.state.data.departureDate,
            departureTime: location.state.data.departureTime,
            drivingArrivalTime: location.state.data.drivingArrivalTime,
            drivingArrivalDate: location.state.data.drivingArrivalDate,
        });

        setIsEditing(false);
        setEditClass('disabled-control');
    }, [])

    const handleChange = (e) => {
        if (e.target.type === 'tel') {
            setCodeData({
                ...codeData,
                [e.target.name]: phoneNumberAutoFormat(e.target.value)
            })

        } else {
            setCodeData({
                ...codeData,
                [e.target.name]: e.target.value
            })
        }
    }

    const phoneNumberAutoFormat = (phoneNumber) => {
        const number = phoneNumber.trim().replace(/[^0-9]/g, "");

        if (number.length < 4) return number;
        if (number.length < 7) return number.replace(/(\d{3})(\d{1})/, "$1-$2");
        if (number.length < 11) return number.replace(/(\d{3})(\d{3})(\d{1})/, "$1-$2-$3");
        return number.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
    };

    const handleEdit = () => {
        if (isEditing)
            setEditClass('disabled-control');
        else
            setEditClass('');

        setIsEditing(!isEditing);
    }

    const handleDsmEdit = () => {
        if (isDsmEditing)
            setDsmClass('disabled-control');
        else
            setDsmClass('');

        setIsDsmEditing(!isDsmEditing);
    }

    const handleDsrEdit = () => {
        if (isDsrEditing)
            setDsrClass('disabled-control');
        else
            setDsrClass('');

        setIsDsrEditing(!isDsrEditing);
    }

    const handleRsmEdit = () => {
        if (isRsmEditing)
            setRsmClass('disabled-control');
        else
            setRsmClass('');

        setIsRsmEditing(!isRsmEditing);
    }

    const handleFormSubmit = (e) => {
        e.preventDefault();
        let successFunction;

        switch (e.target.dataset.type) {
            case 'full':
                successFunction = handleEdit();
                break;
            case 'dsm':
                successFunction = handleDsmEdit();
                break;
            case 'dsr':
                successFunction = handleDsrEdit();
                break;
            case 'rsm':
                successFunction = handleRsmEdit();
                break;
            default:
                successFunction = navigate('/my-vollrath-university');
        }

        updateEventData(codeData)
            .then(data => {
                if (data) {
                    toast.success("Event information has been updated successfully");
                    successFunction;
                } else {
                    toast.error("There was an issue updating the event information");
                }
            })
            .catch(error => {
                console.log(error);
                toast.error("There was an issue updating the event information");
            });
    }

    const handleDsrSendInvite = () => {

        const params = {
            email: codeData.dsr,
            dsm: false,
            eventUrl: location.state.data.eventUrl,
            eventName: location.state.data.eventDetails.title
        }

        if (window.confirm("Are you sure you want to send this DSR a notification email?")) {
            sendNotificationEmail(params)
                .then(data => {
                    if (data) {
                        toast.success("We have notified the DSR about this event.");
                    } else {
                        toast.error("There was an issue sending the notification email. Please make sure you have a valid email in place");
                    }
                })
        }
    }

    const handleDsmSendInvite = () => {

        const params = {
            email: codeData.dsm,
            dsm: true
        }

        if (window.confirm("Are you sure you want to send this DSM a notification email?")) {
            sendNotificationEmail(params)
                .then(data => {
                    if (data) {
                        toast.success("We have notified the DSM about this code.");
                    } else {
                        toast.error("There was an issue sending the notification email. Please make sure you have a valid email in place");
                    }
                })
        }
    }

    const handleRsmSendInvite = () => {

        const params = {
            email: codeData.rsm,
            dsm: true
        }

        if (window.confirm("Are you sure you want to send this RSM a notification email?")) {
            sendNotificationEmail(params)
                .then(data => {
                    if (data) {
                        toast.success("We have notified the RSM about this code.");
                    } else {
                        toast.error("There was an issue sending the notification email. Please make sure you have a valid email in place");
                    }
                })
        }
    }

    return (
        <>
            <div className="container">
                <div className="row section">
                    <div className="col-md-12 add-margin-btm-extra">
                        <NavLink to={`/my-vollrath-university`}>
                            Return
                        </NavLink>
                    </div>
                    {location.state.data.isRegistered &&
                        <div className="col-md-12">
                            <div className="section-title section-header profile">{location.state.data.eventDetails.title} - Edit Information</div>
                            {!isEditing ? <button className="btn add-margin-btm" onClick={() => handleEdit()}>Edit</button> :
                                <div>
                                    <button data-type="full" className="btn add-margin-btm" onClick={() => handleFormSubmit(event)}>Save</button>
                                    <button className="btn add-margin-btm" style={{ marginLeft: '5px' }} onClick={() => handleEdit()}>Cancel</button>
                                </div>}
                        </div>
                    }
                    <form className="col-md-8" id="event-fly-confirmation-form" action="#" method="post" onSubmit={handleFormSubmit}>
                        <div id="top-of-form" className="row">
                            <div className={`form-group col-lg-4 col-md-6`}>
                                <label htmlFor="dsm">RSM</label>
                                <input className={`form-control ${rsmClass}`} type="text" id="dsm" name="dsm" value={codeData.rsm} onChange={handleChange} disabled={!isRsmEditing} />
                                {!isRsmEditing ?
                                    <>
                                        <div onClick={() => handleRsmEdit()} style={{ display: 'inline-block' }} className="text-link quiz-btn">Edit</div>
                                        <div onClick={() => handleRsmSendInvite()} style={{ display: 'inline-block', marginLeft: '15px' }} className="text-link quiz-btn">Notify</div>
                                    </> :
                                    <>
                                        <div data-type="rsm" onClick={() => handleFormSubmit(event)} style={{ display: 'inline-block' }} className="text-link quiz-btn">Save</div>
                                        <div onClick={() => handleRsmEdit()} style={{ display: 'inline-block', marginLeft: '5px' }} className="text-link quiz-btn">Cancel</div>
                                    </>
                                }
                            </div>
                            <div className={`form-group col-lg-4 col-md-6`}>
                                <label htmlFor="dsm">DSM</label>
                                <input className={`form-control ${dsmClass}`} type="text" id="dsm" name="dsm" value={codeData.dsm} onChange={handleChange} disabled={!isDsmEditing} />
                                {!isDsmEditing ?
                                    <>
                                        <div onClick={() => handleDsmEdit()} style={{ display: 'inline-block' }} className="text-link quiz-btn">Edit</div>
                                        <div onClick={() => handleDsmSendInvite()} style={{ display: 'inline-block', marginLeft: '15px' }} className="text-link quiz-btn">Notify</div>
                                    </> :
                                    <>
                                        <div data-type="dsm" onClick={() => handleFormSubmit(event)} style={{ display: 'inline-block' }} className="text-link quiz-btn">Save</div>
                                        <div onClick={() => handleDsmEdit()} style={{ display: 'inline-block', marginLeft: '5px' }} className="text-link quiz-btn">Cancel</div>
                                    </>
                                }
                            </div>
                            <div className={`form-group col-lg-4 col-md-6`}>
                                <label htmlFor="dsr">DSR</label>
                                <input className={`form-control ${dsrClass}`} type="text" id="dsr" name="dsr" value={codeData.dsr} onChange={handleChange} disabled={!isDsrEditing} />
                                {!isDsrEditing ?
                                    <>
                                        <div onClick={() => handleDsrEdit()} style={{ display: 'inline-block' }} className="text-link quiz-btn">Edit</div>
                                        <div onClick={() => handleDsrSendInvite()} style={{ display: 'inline-block', marginLeft: '15px' }} className="text-link quiz-btn">Notify</div>
                                    </> :
                                    <>
                                        <div data-type="dsr" onClick={() => handleFormSubmit(event)} style={{ display: 'inline-block' }} className="text-link quiz-btn">Save</div>
                                        <div onClick={() => handleDsrEdit()} style={{ display: 'inline-block', marginLeft: '5px' }} className="text-link quiz-btn">Cancel</div>
                                    </>
                                }
                            </div>

                            <div className="col-md-12">
                                <div className="section-title section-header profile">Travel Information</div>
                            </div>

                            <div className="form-group col-lg-4 col-md-6">
                                <label htmlFor="hotelRoom">Hotel Room</label>
                                <input className={`form-control ${editClass}`} type="text" id="hotelRoom" name="hotelRoom" value={codeData.hotelRoom} onChange={handleChange} disabled={!isEditing} />
                            </div>
                            <div className="form-group col-lg-4 col-md-6">
                                <label htmlFor="arrivalCarService">Arrival Car Service</label>
                                <input className={`form-control ${editClass}`} type="text" id="arrivalCarService" name="arrivalCarService" value={codeData.arrivalCarService} onChange={handleChange} disabled={!isEditing} />
                            </div>
                            <div className="form-group col-lg-4 col-md-6">
                                <label htmlFor="departureCarService">Departure Car Service</label>
                                <input className={`form-control ${editClass}`} type="text" id="departureCarService" name="departureCarService" value={codeData.departureCarService} onChange={handleChange} disabled={!isEditing} />
                            </div>
                            <div className="form-group col-lg-4 col-md-6">
                                <label htmlFor="rentalCar">Rental Car</label>
                                <input className={`form-control ${editClass}`} type="text" id="rentalCar" name="rentalCar" value={codeData.rentalCar} onChange={handleChange} disabled={!isEditing} />
                            </div>
                            <div className="form-group col-lg-4 col-md-6">
                                <label htmlFor="travelMethod">Travel Method</label>
                                <input className={`form-control ${editClass}`} type="text" id="travelMethod" name="travelMethod" value={codeData.travelMethod} onChange={handleChange} disabled={!isEditing} />
                            </div>
                            <div className="form-group col-lg-4 col-md-6">
                                <label htmlFor="arrivalAirline">Arrival Airline</label>
                                <input className={`form-control ${editClass}`} type="text" id="arrivalAirline" name="arrivalAirline" value={codeData.arrivalAirline} onChange={handleChange} disabled={!isEditing} />
                            </div>
                            <div className="form-group col-lg-4 col-md-6">
                                <label htmlFor="arrivalFlightNumber">Arrival Flight Number</label>
                                <input className={`form-control ${editClass}`} type="text" id="arrivalFlightNumber" name="arrivalFlightNumber" value={codeData.arrivalFlightNumber} onChange={handleChange} disabled={!isEditing} />
                            </div>
                            <div className="form-group col-lg-4 col-md-6">
                                <label htmlFor="arrivalDate">Arrival Date</label>
                                <input className={`form-control ${editClass}`} type="text" id="arrivalDate" name="arrivalDate" value={codeData.arrivalDate} onChange={handleChange} disabled={!isEditing} />
                            </div>
                            <div className="form-group col-lg-4 col-md-6">
                                <label htmlFor="arrivalTime">Arrival Time</label>
                                <input className={`form-control ${editClass}`} type="text" id="arrivalTime" name="arrivalTime" value={codeData.arrivalTime} onChange={handleChange} disabled={!isEditing} />
                            </div>
                            <div className="form-group col-lg-4 col-md-6">
                                <label htmlFor="departureAirline">Departure Airline</label>
                                <input className={`form-control ${editClass}`} type="text" id="departureAirline" name="departureAirline" value={
                                    codeData.departureAirline} onChange={handleChange} disabled={!isEditing} />
                            </div>
                            <div className="form-group col-lg-4 col-md-6">
                                <label htmlFor="departureFlightNumber">Departure Flight Number</label>
                                <input className={`form-control ${editClass}`} type="text" id="departureFlightNumber" name="departureFlightNumber" value={codeData.departureFlightNumber} onChange={handleChange} disabled={!isEditing} />
                            </div>
                            <div className="form-group col-lg-4 col-md-6">
                                <label htmlFor="departureDate">Departure Date</label>
                                <input className={`form-control ${editClass}`} type="text" id="departureDate" name="departureDate" value={codeData.departureDate} onChange={handleChange} disabled={!isEditing} />
                            </div>
                            <div className="form-group col-lg-4 col-md-6">
                                <label htmlFor="departureTime">Departure Time</label>
                                <input className={`form-control ${editClass}`} type="text" id="departureTime" name="departureTime" value={codeData.departureTime} onChange={handleChange} disabled={!isEditing} />
                            </div>
                            <div className="form-group col-lg-4 col-md-6">
                                <label htmlFor="drivingArrivalTime">Driving Arrival Time</label>
                                <input className={`form-control ${editClass}`} type="text" id="drivingArrivalTime" name="drivingArrivalTime" value={codeData.drivingArrivalTime} onChange={handleChange} disabled={!isEditing} />
                            </div>
                            <div className="form-group col-lg-4 col-md-6">
                                <label htmlFor="drivingArrivalDate">Driving Arrival Date</label>
                                <input className={`form-control ${editClass}`} type="text" id="drivingArrivalDate" name="drivingArrivalDate" value={codeData.drivingArrivalDate} onChange={handleChange} disabled={!isEditing} />
                            </div>
                            <div className="col-md-12">
                                <div className="section-title section-header profile">Personal Information</div>
                            </div>
                            <div className="form-group col-lg-4 col-md-6">
                                <label htmlFor="firstName">First Name</label>
                                <input className={`form-control ${editClass}`} type="text" id="firstName" name="firstName" value={codeData.firstName} onChange={handleChange} disabled={!isEditing} />
                            </div>
                            <div className="form-group col-lg-4 col-md-6">
                                <label htmlFor="lastName">Last Name</label>
                                <input className={`form-control ${editClass}`} type="text" id="lastName" name="lastName" value={codeData.lastName} onChange={handleChange} disabled={!isEditing} />
                            </div>
                            <div className="form-group col-lg-4 col-md-6">
                                <label htmlFor="email">Email</label>
                                <input className={`form-control ${editClass}`} type="text" id="email" name="email" value={codeData.email} onChange={handleChange} disabled={!isEditing} />
                            </div>
                            <div className="form-group col-lg-4 col-md-6">
                                <label htmlFor="phoneNumber">Phone Number</label>
                                <input className={`form-control ${editClass}`} type="tel" id="phoneNumber" name="phoneNumber" value={codeData.phoneNumber} onChange={handleChange} disabled={!isEditing} />
                            </div>
                            <div className="form-group col-lg-4 col-md-6">
                                <label htmlFor="address">Address</label>
                                <input className={`form-control ${editClass}`} type="text" id="address" name="address" value={codeData.address} onChange={handleChange} disabled={!isEditing} />
                            </div>
                            <div className="form-group col-lg-4 col-md-6">
                                <label htmlFor="city">City</label>
                                <input className={`form-control ${editClass}`} type="text" id="city" name="city" value={codeData.city} onChange={handleChange} disabled={!isEditing} />
                            </div>
                            <div className="form-group col-lg-4 col-md-6">
                                <label htmlFor="state">State</label>
                                <input className={`form-control ${editClass}`} type="text" id="state" name="state" value={codeData.state} onChange={handleChange} disabled={!isEditing} />
                            </div>
                            <div className="form-group col-lg-4 col-md-6">
                                <label htmlFor="postalCode">Postal Code</label>
                                <input className={`form-control ${editClass}`} type="text" id="postalCode" name="postalCode" value={codeData.postalCode} onChange={handleChange} disabled={!isEditing} />
                            </div>
                            <div className="col-md-12">
                                <div className="section-title section-header profile">Event Information</div>
                            </div>
                            <div className="form-group col-lg-4 col-md-6">
                                <label htmlFor="vollrathDealer">Vollrath Dealer</label>
                                <input className={`form-control ${editClass}`} type="text" id="vollrathDealer" name="vollrathDealer" value={codeData.vollrathDealer} onChange={handleChange} disabled={!isEditing} />
                            </div>
                            <div className="form-group col-lg-4 col-md-6">
                                <label htmlFor="jobFunction">Job Function</label>
                                <input className={`form-control ${editClass}`} type="text" id="jobFunction" name="jobFunction" value={codeData.jobFunction} onChange={handleChange} disabled={!isEditing} />
                            </div>
                            <div className="form-group col-lg-4 col-md-6">
                                <label htmlFor="jobTitle">Job Title</label>
                                <input className={`form-control ${editClass}`} type="text" id="jobTitle" name="jobTitle" value={codeData.jobTitle} onChange={handleChange} disabled={!isEditing} />
                            </div>
                            <div className="form-group col-lg-4 col-md-6">
                                <label htmlFor="over21">Over 21</label>
                                <input className={`form-control ${editClass}`} type="text" id="over21" name="over21" value={codeData.over21} onChange={handleChange} disabled={!isEditing} />
                            </div>
                            <div className="form-group col-lg-4 col-md-6">
                                <label htmlFor="accomodations">Accomodations</label>
                                <input className={`form-control ${editClass}`} type="text" id="accomodations" name="accomodations" value={codeData.accomodations} onChange={handleChange} disabled={!isEditing} />
                            </div>
                            <div className="form-group col-lg-4 col-md-6">
                                <label htmlFor="dietaryNeeds">Dietary Needs</label>
                                <input className={`form-control ${editClass}`} type="text" id="dietaryNeeds" name="dietaryNeeds" value={codeData.dietaryNeeds} onChange={handleChange} disabled={!isEditing} />
                            </div>
                            <div className="form-group col-lg-4 col-md-6">
                                <label htmlFor="badgeName">Badge Name</label>
                                <input className={`form-control ${editClass}`} type="text" id="badgeName" name="badgeName" value={codeData.badgeName} onChange={handleChange} disabled={!isEditing} />
                            </div>
                            <div className="form-group col-lg-4 col-md-6">
                                <label htmlFor="emergencyContactName">Emergency Contact Name</label>
                                <input className={`form-control ${editClass}`} type="text" id="emergencyContactName" name="emergencyContactName" value={codeData.emergencyContactName} onChange={handleChange} disabled={!isEditing} />
                            </div>
                            <div className="form-group col-lg-4 col-md-6">
                                <label htmlFor="emergencyContactPhoneNumber">Emergency Contact Phone Number</label>
                                <input className={`form-control ${editClass}`} type="text" id="emergencyContactPhoneNumber" name="emergencyContactPhoneNumber" value={codeData.emergencyContactPhoneNumber} onChange={handleChange} disabled={!isEditing} />
                            </div>
                            {location.state.data.IsRegistered && isEditing &&
                                <div className="form-actions col-md-12">
                                    <button data-type="full" className="btn" type="submit" >Save</button>
                                </div>
                            }
                        </div>
                    </form>
                    <div className="col-md-4">
                        <p className="vu-dashboard-content">{`Event Date: ${startDate} - ${endDate}`}  </p>
                        <a className="text-link" href={location.state.data.EventUrl}><p><strong>{`Invite Code: ${location.state.data.registrationCode}`}</strong></p></a>
                        <p className="vu-dashboard-content">{`Used By: ${location.state.data.usedBy}`}</p>
                        <p className="vu-dashboard-content">{`${location.state.data.firstName} ${location.state.data.lastName}`}</p>
                        <p className="vu-dashboard-content">{`Is Registered: ${location.state.data.isRegistered}`}</p>
                        <p className="vu-dashboard-content">{`Is Confirmed: ${location.state.data.isConfirmed}`}</p>
                    </div>
                </div>
            </div>

        </>
    );
}

export default DashboardModule;