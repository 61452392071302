import React, { useState, useEffect } from 'react';
import { useCurrentProjectIdContext } from "./project-builder-main.jsx";
import { useUpdateProductGroup } from '../../../components/project-builder-custom-hooks';
import { useQuery } from '@tanstack/react-query';
import { getProject } from '../../../apis/project-builder';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ProductCard from './product-card.jsx';
import DeleteModal from './delete-modal.jsx';
import ProductsListByFamilyPdfView from './products-list-by-family-pdf.jsx';
//import CustomGroupModal from './custom-group-modal.jsx';

export default function ProductsFamilyGroup({ selectedProductIds, setSelectedProductIds, family, index, families, setFamilies, expandAll, reviewList, readOnly, groupId, groupSortOrder }) {

    const { currentProjectId } = useCurrentProjectIdContext();

    const { data } = useQuery(['project-detail', currentProjectId], () => getProject(currentProjectId));

    const { mutate: updateProductGroup, isLoading } = useUpdateProductGroup(currentProjectId);

    const [expanded, setExpanded] = useState(true);

    const toggleAccordion = () => {
        setExpanded((prev) => !prev);
    }

    const [isDeleteOpen, setIsDeleteOpen] = useState(false);

    const openDeleteModal = () => setIsDeleteOpen(true);
    const closeDeleteModal = () => setIsDeleteOpen(false);


    let productsInFamily = [];

    if (data.SortType == "ProductFamilyGrouping") productsInFamily = data.Products.filter((product) => product.Family === family);

    if (data.SortType == "CustomGrouping") productsInFamily = data.Products.filter((product) => product.ProductGroupId == groupId);

    let totalAccessoryProductCount = 0;

    productsInFamily.map((product) => {
        totalAccessoryProductCount += product.Accessories.length
    });

    let totalProductCount = productsInFamily.length + totalAccessoryProductCount;


    const handleFamilyMoveUp = (event, index) => {
        event.stopPropagation();

        if (data.SortType == "CustomGrouping") {
            updateProductGroup({
                id: groupId,
                sortOrder: groupSortOrder - 1,
            })
            return;
        }

        if (index === 0) return;
        const items = [...families];
        const newIndex = index - 1;
        const itemAbove = items[newIndex];
        items[index - 1] = items[index];
        items[index] = itemAbove;
        setFamilies(items);

    }

    const handleFamilyMoveDown = (event, index) => {
        event.stopPropagation();

        if (data.SortType == "CustomGrouping") {
            updateProductGroup({
                id: groupId,
                sortOrder: groupSortOrder + 1,
            })
            return;
        }

        const items = [...families];
        if (index === items.length - 1) return;
        const newIndex = index + 1;
        const itemBelow = items[newIndex];
        items[index + 1] = items[index];
        items[index] = itemBelow;
        setFamilies(items);
    }

    useEffect(() => {
        setExpanded(expandAll);
    }, [expandAll])

    let cardCount = 0;

    const getColSpan = () => {
        cardCount = cardCount + 1;
        return (cardCount % 3 === 0 || reviewList) ? "12" : "6";
    }

    const isFullWidth = () => {
        return (reviewList ? true : (cardCount % 3 === 0) ? true : false);
    }

    return (
        <>
            <div className="pdf-view-only">
                <div className="section-title--family">{family}</div>
                {productsInFamily.map((product, index) => (
                    <>
                        <ProductsListByFamilyPdfView
                            index={index}
                            product={product}
                            isMarketingProject={data.IsMarketingProject}
                        />
                        {product.Accessories.map((accessory, accessoryIndex) => (
                            <ProductsListByFamilyPdfView
                                index={accessoryIndex}  
                                product={accessory}
                                parentId={product.Sku}
                                isMarketingProject={data.IsMarketingProject}
                                isAccessory
                            />
                        ))}
                    </>
                ))}
            </div>

            <DeleteModal isDeleteOpen={isDeleteOpen} closeDeleteModal={closeDeleteModal} type="family" productsInFamily={productsInFamily} />
            <Accordion expanded={expanded} data-index={index} key={index} className="family-accordion hide-on-pdf">
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="kitchenEssentials-content"
                    onClick={toggleAccordion}
                >
                    {readOnly ? null
                        : <div className="popover-trigger meatball ml-auto">
                            <span></span>
                            <span></span>
                            <span></span>
                            <ul className="menu-dropdown">
                                {index > 0 &&
                                    <li onClick={() => handleFamilyMoveUp(event, index)}>Move section up</li>
                                }
                                {index < (families.length - 1) &&
                                    <li onClick={() => handleFamilyMoveDown(event, index)} >Move section down</li>
                                }
                                <li onClick={openDeleteModal}>Delete section</li>
                                {/*{data.SortType == "CustomGrouping" &&*/}
                                {/*    <CustomGroupModal*/}
                                {/*        buttonClass="link-btn"*/}
                                {/*        openButtonText="Edit Group"*/}
                                {/*        type="Edit"*/}
                                {/*    />*/}
                                {/*}*/}
                            </ul>
                        </div>
                    }
                    <div className="section-title--family">
                        {readOnly ? null    
                            : <span className="menu-toggle-lines"></span>
                        }
                        {family}
                        <span> {totalProductCount} products hidden</span>
                    </div>
                </AccordionSummary>

                <AccordionDetails>
                    <div className="row">
                        {productsInFamily.map((product, index) => (
                            <>
                                <div class={`col-md-${getColSpan()} ${reviewList ? "" : "mb-2"}`}>
                                <ProductCard
                                    isSelectable={!reviewList}
                                    isFullWidth={isFullWidth()}
                                    key={product.Id}
                                    product={product}
                                    selectedProductIds={selectedProductIds}
                                    setSelectedProductIds={setSelectedProductIds}
                                    readOnly={readOnly}
                                    sortType={data.SortType}
                                />
                            </div>
                                {product.Accessories.map((accessory, accessoryIndex) => (
                                    <div class={`col-md-${getColSpan()} ${reviewList ? "" : "mb-2"}`}>
                                        <ProductCard
                                            isSelectable={!reviewList}
                                            isFullWidth={isFullWidth()}
                                            reviewList={reviewList}
                                            readOnly={readOnly}
                                            key={accessory.Id}
                                            product={accessory}
                                            parentId={product.Sku}
                                            selectedProductIds={selectedProductIds}
                                            setSelectedProductIds={setSelectedProductIds}
                                            sortType={data.SortType}
                                            isAccessory
                                        />
                                    </div>
                                ))
                            }
                            </>
                        ))}
                    </div>
                </AccordionDetails>
            </Accordion>
        </>
    );
}