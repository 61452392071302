import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import { useCurrentProjectIdContext } from "./project-builder-main.jsx";
import { useDuplicateProject } from "../../../components/project-builder-custom-hooks";
import { getProject } from '../../../apis/project-builder';
//import toast from '../../../../shared/components/toast';
import jfetch from '@jontetz/jfetch';
import Modal from 'react-modal';
import ProjectFormFields from './project-form-fields.jsx';


export default function DuplicateProjectModal({ buttonClass, openButtonText, projectId, setShowDropdown }) {
    Modal.setAppElement('#main');

    const navigate = useNavigate();
    //const queryClient = useQueryClient();
    const { currentProjectId, setCurrentProjectId } = useCurrentProjectIdContext();

    const { data, isSuccess, isLoading, isError, error } = useQuery(['project-detail', projectId ? projectId : currentProjectId], () => getProject(projectId ? projectId : currentProjectId));

    const { mutate: duplicateProject, isLoading: duplicateIsLoading } = useDuplicateProject(projectId ? projectId : currentProjectId);

    const [isOpen, setIsOpen] = useState(false);

    const [formData, setFormData] = useState({
        projectName: "",
        id: "",
        accountName: "",
        zipCode: "",
        phoneNumber: "",
        emailAddress: "",
        status: { value: "", label: "" },
        type: { value: "", label: "" },
        notes: "",
    });



    const openModal = () => {
        setIsOpen(true);
    }

    const closeModal = () => {
        setIsOpen(false);
    }

    const handleFormSubmit = (event) => {
        event.preventDefault();
        closeModal();
        duplicateProject({
            onSuccess: (newData) => {
                setShowDropdown ? setShowDropdown(false) : null;
                //closeModal();
                let newProjectId = newData.Id;
                setCurrentProjectId(newProjectId);
                navigate(`/project-builder/${newProjectId}`);
            }
        });
    }

    useEffect(() => {
        if (isSuccess) {
            setFormData({
                projectName: data.Name,
                id: data.Id,
                accountName: data.AccountName,
                zipCode: data.ZipCode,
                phoneNumber: data.ContactPhoneNumber,
                emailAddress: data.ContactEmailAddress,
                status: { value: data.Status, label: data.Status },
                type: { value: data.Type, label: data.Type },
                notes: data.Notes,
            })
        }
    }, [data])

    return (
        <>
            {isSuccess && 
                <>
                    <button className={buttonClass} type='button' onClick={openModal}><span className="icon icon--duplicate"></span>{openButtonText}</button>
                    <Modal
                        isOpen={isOpen}
                        onRequestClose={closeModal}
                        className="project-builder-modal project-builder-modal--large"
                    >
                        <button className="project-builder-modal-close" onClick={closeModal}>
                            <svg id="header-ask-close-svg" className="icon icon-close"><use xlinkHref="#icon-close"></use></svg>
                        </button>
                        <div className="container project-builder">
                            <div className='modal-title'>Duplicate Project</div>

                            <form className="project-builder-form" method="post" onSubmit={handleFormSubmit}>

                                <ProjectFormFields
                                    formData={formData}
                                    setFormData={setFormData}
                                    readOnly
                                />

                                <div className="form-actions">
                                    <button className='btn btn-outline-alt' type='button' disabled={duplicateIsLoading} onClick={closeModal} >delete project</button>
                                    <button className='btn no-arrow' type='submit' disabled={duplicateIsLoading} >save changes</button>
                                </div>
                            </form>
                        </div>
                        </Modal>
                </>
            }
        </>
    );
}