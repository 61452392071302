import React, { Component } from 'react';
//import ReactDOM from 'react-dom';
import SearchMenuBase from '../../../../shared/react/components/search-menu-base.jsx';
import LoaderSection from '../../../../shared/react/components/loader-section.jsx';
import AddRemoveProductsButton from './add-remove-products-button.jsx';
import AddRemoveDocumentsButton from './add-remove-documents-button.jsx';
import SearchForm from './search-form.jsx';

class SearchMenuContainer extends SearchMenuBase {
    constructor(props) {
        super(props);

        this.handleDocumentClick = this.handleDocumentClick.bind(this);
        //this.handleKeyup = this.handleKeyup.bind(this);
        this.handleCategoryClick = this.handleCategoryClick.bind(this);

    }

    /*componentDidUpdate(prevProps, prevState) {

        if (prevState.isOpen !== this.state.isOpen) {
            if (this.state.isOpen) {
                setTimeout(() => {
                    //this.searchInput.current.focus();
                    document.addEventListener('click', this.handleDocumentClick, false);
                    document.addEventListener('keyup', this.handleKeyup);
                }, 400);
            }
            else {
                document.removeEventListener('click', this.handleDocumentClick, false);
                document.removeEventListener('keyup', this.handleKeyup);
            }
        }

    }

    componentDidMount() {

        document.addEventListener('click', this.handleDocumentClick, false);
    }*/


    handleCategoryClick(e) {
        this.setState({
            category: e.target.dataset.name
        });
    }

    handleDocumentClick(e) {
        //if (this.searchDrawer.current.contains(e.target)) {
        if (this.searchInput.current.contains(e.target)) {
            return;
        }

        this.setState({
            isOpen: false,
        });
    }

    /*handleKeyup(e) {
        switch (e.keyCode) {
            // esc key
            case 27:
                this.setState({
                    isOpen: false
                });
                break;
        }
    }*/

    renderForm() {
        let loader,
            noResults,
            content;

        if (this.state.isLoading) {
            loader = <LoaderSection className="short" show={true} />;
        }

        //let quickLinks = this.state.quickLinks.map((item, index) =>
        //    <li key={index}><a href={item.PageUrl}>{item.PageName}</a></li>
        //)

        if (!this.state.isLoading &&
            this.state.productItems.length === 0 &&
            this.state.keywordItems.length === 0 &&
            this.state.documentItems.length === 0 &&
            this.state.q) {
            noResults = <p className="search-drawer-no-results">No results found</p>;
        }

        if (this.state.q.length) {
            content = (
                <div className="search-drawer-content" >
                    {loader}
                    {noResults}
                    {
                        this.state.productItems.length > 0 &&
                        <section className="search-drawer-section">
                            <h4 className="search-drawer-title">Products</h4>
                            <div className="search-drawer-section-content">
                                <ul className="search-drawer-links">
                                    {this.state.productItems.map((item, index) => (
                                        <li key={item.NodeId}>
                                            <a href={item.PageUrl} target="_blank">{item.SKUNumber} - {item.DocumentName}</a>
                                            <AddRemoveProductsButton productNodeId={item.NodeId} />
                                        </li>
                                    ))}
                                    {this.state.productItems.length === 5 &&
                                        <li><button data-name="products" className="search-drawer-all-btn" onClick={this.handleCategoryClick}>View All &gt;</button></li>
                                    }
                                </ul>
                            </div>
                        </section>
                    }
                    {
                        this.state.keywordItems.length > 0 &&
                        <section className="search-drawer-section">
                            <h4 className="search-drawer-title">Categories</h4>
                            <div className="search-drawer-section-content">
                                <ul className="search-drawer-links">
                                    {this.state.keywordItems.map((item, index) => (
                                        <li key={item.NodeId}>
                                            <a href={item.PageUrl} target="_blank">{item.DocumentName}</a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </section>
                    }
                    {
                        this.state.documentItems.length > 0 &&
                        <section className="search-drawer-section">
                            <h4 className="search-drawer-title">Documents</h4>
                            <div className="search-drawer-section-content">
                                <ul className="search-drawer-links">
                                    {this.state.documentItems.map((item, index) => (
                                        <li key={item.NodeId}>
                                            <a href={`/downloads/${item.LocalSlug}`} target="_blank">{item.DisplayName}</a>
                                            <AddRemoveDocumentsButton itemSlug={item.LocalSlug} />
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </section>
                    }
                </div>
            );
        }

        const form = (
            <div className="search-form-container" >
                <SearchForm content={content} q={this.state.q} searchInput={this.searchInput} handleChange={this.handleChange} inputValue={this.state.category} />
                {
                    /* <form className="search-form" method="get" onSubmit={this.handleFormSubmit}>
                       <div className="search-drawer-input-container">
                           <label className="sr-only" htmlFor="search-field">Search</label>
                           <input ref={this.searchInput} className="search-drawer-input" id="search-field" type="text" name="q" placeholder="Search..." onChange={this.handleChange} required autoCapitalize="none" autoCorrect="off" />
                           <input type="hidden" name="category" value={this.state.category} />
                           <button className="search-drawer-btn">
                               <svg className="icon" width="40" height="40"><use xlinkHref="#icon-search"></use></svg>
                               <span className="sr-only">Search</span>
                           </button>
                       </div>
                       {content}
                   </form>
                   */
                }
            </div>
        );

        //return ReactDOM.createPortal(drawer, document.body);
        return form;

    }

    render() {
        return this.renderForm();
        /*return [
            <button key="searchBtn" className="search-drawer-trigger" type="button" onClick={this.toggleSearch}>
                <svg className="icon" width="35" height="35"><use xlinkHref="#icon-search"></use></svg>
                <span className="search-drawer-label">Search...</span>
                <span className="sr-only">search icon</span>
            </button>,
            this.renderPortal()
        ];*/
    }
}

export default SearchMenuContainer;