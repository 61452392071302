import React, { useState } from 'react';
import DisplayModules from './DisplayModules.jsx';

export default function Step4({ selectedLayout, moduleOptions, selectedComponents, setSelectedComponents }) {
	const [showTypeList, setShowTypeList] = useState(false);
	const [showLidsList, setShowLidsList] = useState(false);
	const [activeModuleIndex, setactiveModuleIndex] = useState();

	const layoutOrientation = selectedLayout.displayName.split(" ")[1];
	const layoutModuleCount = selectedLayout.displayName.split(" ")[0];
	let moduleCount = 1;

	if (layoutModuleCount === "2") { moduleCount = 2; }
	if (layoutModuleCount === "3") { moduleCount = 3; }
	if (layoutModuleCount === "4") { moduleCount = 4; }

	// Build module type list
	let moduleLidList = moduleOptions.slice(-1).pop();
	let [...moduleOptionsWithoutLids] = [...moduleOptions];
	moduleOptionsWithoutLids.pop();

	// flatten array of cup components
	let moduleComponentsList = [];
	moduleOptionsWithoutLids.map((item) => {
		return moduleComponentsList.push(item.components)
	});
	moduleComponentsList = [].concat.apply([], moduleComponentsList);
	moduleComponentsList.push(moduleLidList);

	// Handlers
	const updateSelectedComponents = (value, index) => {
		let updatedValues = [...selectedComponents];
		updatedValues[index] = value;
		setSelectedComponents(updatedValues);
	}

	const handleModuleClick = (moduleIndex) => {
		setactiveModuleIndex(moduleIndex);
		setShowTypeList(true);
	}

	const handleSelectType = (event) => {
		let index = event.target.getAttribute("data-index");
		updateSelectedComponents(moduleComponentsList[index], activeModuleIndex);
		setShowTypeList(false);
	}

	const handleSelectLid = (event) => {
		let index = event.target.selectedIndex;
		updateSelectedComponents(moduleLidList.components[index - 1], activeModuleIndex);
		setactiveModuleIndex(null);
		setShowLidsList(false);
		setShowTypeList(false);
	}

	return (
		<div className="lidSaverContainer lidSaverContainerStep4">
			<div className="stepHeader">
				<h2>Configure Dispenser Modules</h2>
				<p>Click on each square to configure it</p>
			</div>

			<div className={`stepContainer configureModules ${layoutOrientation}`}>
				<p className="modulesDescription">{selectedLayout.displayName}</p>
				<div className="modulesContainer">
					<DisplayModules
						moduleCount={moduleCount}
						activeModuleIndex={activeModuleIndex}
						selectedComponents={selectedComponents}
						onClick={handleModuleClick}
					/>

					<div id="moduleTypeList" className={`selectModuleType ${showTypeList ? 'is-active' : ""}`}>
						<div className="selectModuleTypeInner">
							<span className="list-close" onClick={() => setShowTypeList(false)}></span>
							<h4>Select Module Type</h4>
							<ul>
								{moduleComponentsList.map((option, i) => {
									if (option.code === "L") {
										return <li
											key={i}
											/*className={typeCodesMinusLids.includes(selectedComponents[activeModuleIndex]) || selectedComponents[activeModuleIndex] == null ? "" : "is-selected"}*/
											className={`${selectedComponents[activeModuleIndex] == null ? "" : (selectedComponents[activeModuleIndex].code.length > 1 ? "is-selected" : "")}`}
											data-value="L"
											onClick={() => setShowLidsList(true)}
										>
											Lid</li>
									}
									else {
										return <li
											key={i}
											className={`${selectedComponents[activeModuleIndex] == null ? "" : (selectedComponents[activeModuleIndex].code === option.code ? "is-selected" : "")}`}
											data-value={option.code}
											data-index={i}
											onClick={handleSelectType}
										>
											{/*(option.ModuleCode === "S" ? "Straw (" + option.ModuleCode + ")" : (option.ModuleCode === "P" ? "Condiment (" + option.ModuleCode + ")" : "Cup (" + option.Code + ") " + option.DisplayName))*/}
											{option.displayName}
										</li>
									}
								})
								}
							</ul>
						</div>
					</div>

					<div id="moduleLidsList" className={`selectModuleType selectModuleType--lids ${showLidsList === true ? 'is-active' : ""}`}>
						<div className="selectModuleTypeInner">
							<span className="list-close" onClick={() => setShowLidsList(false)}></span>
							<h4>Select Lid Code</h4>
							<select id="LidCode" value="" onChange={handleSelectLid}>
								<option value="">- Select one -</option>
								{moduleLidList.components.map((lid, i) => (
									<option key={i} value={lid.code}>{lid.displayName}</option>
								))}
							</select>
							<p>If your lid code is not listed, please contact Vollrath Customer Service at vollrathfs@vollrathco.com or 800-628-0830.</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
