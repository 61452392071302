import React, { useState } from 'react';

import GlassType from './GlassType.jsx';
import GlassInfo from './GlassInfo.jsx';
import RackColors from './RackColors.jsx';
import RackSize from './RackSize.jsx';
import RackBrand from './RackBrand.jsx';
import Results from './Results.jsx';
import ContactForm from './ContactForm.jsx';

function App() {

    const isSales = document.getElementById("wwConfigurator").getAttribute("data-is-sales");

    const [type, setType] = useState(null);
    const [infoSelected, setInfoSelected] = useState(false);
    const [searchBySize, setSearchBySize] = useState(true);
    const [isColorsSelected, setIsColorsSelected] = useState(false);

    const [modelInfo, setModelInfo] = useState({
        manufacturer: null,
        model: null,
        dIn: null,
        dCm: null,
        hIn: null,
        hCm: null,
        diagIn: null,
        diagCm: null
    });

    const [sizeInfo, setSizeInfo] = useState({
        maxDia: "",
        maxDiaWithHandle: "",
        maxHeight: "",
        scale: 2
    })

    const [selectedColors, setSelectedColors] = useState({
        rackColorCode: null,
        riserColorCode: null,
        hasDifferentColoredTopRiser: null
    })

    const [size, setSize] = useState(null);
    const [brand, setBrand] = useState(null);
    const [showResults, setShowResults] = useState(false);
    const [rackResults, setRackResults] = useState([]);
    const [rackError, setRackError] = useState(null);
    const [showContactForm, setShowContactForm] = useState(false);
    const [contactFormItemNumber, setContactFormItemNumber] = useState(null);

    //Change Handlers
    const handleSizeChange = (size) => {
        setSize(size);
    }

    const handleBrandChange = (brand) => {
        setBrand(brand);
    }

    const stringToDecimal = (string) => {
        var a = string.split(" ");
        var whole = a[0];
        var fraction = a[1];

        var b = fraction.split("/");
        var numberator = parseInt(b[0]);
        var denominator = parseInt(b[1]);

        return parseFloat(parseInt(whole) + (numberator / denominator)).toFixed(4);
    }

    //Build api urls
    let colorsApiUrl = '';
    let height = "";
    let width = "";
    let diagonal = "";
    let rackSize = "";
    let rackBrand = "";

    if (infoSelected && (brand !== null)) {
        

        if (searchBySize) {
            var decimalMaxHeight = 0;
            var decimalMaxDia = 0;
            var decimalMaxDiaWithHandle = 0;
            //convert inputs to decimal
            sizeInfo.maxHeight.includes(" ") ? decimalMaxHeight = stringToDecimal(sizeInfo.maxHeight) : decimalMaxHeight = parseFloat(sizeInfo.maxHeight);
            sizeInfo.maxDia.includes(" ") ? decimalMaxDia = stringToDecimal(sizeInfo.maxDia) : decimalMaxDia = parseFloat(sizeInfo.maxDia);
            sizeInfo.maxDiaWithHandle.includes(" ") ? decimalMaxDiaWithHandle = stringToDecimal(sizeInfo.maxDiaWithHandle) : decimalMaxDiaWithHandle = parseFloat(sizeInfo.maxDiaWithHandle);

            //backend always takes measurements in inches
            if (sizeInfo.scale === 2) {
                height = (decimalMaxHeight / 2.54).toFixed(4);
                width = (decimalMaxDia / 2.54).toFixed(4);
                (type === 1) ? "" : diagonal = "diagonal=" + (decimalMaxDiaWithHandle / 2.54).toFixed(4) + "&";
            }
            else {
                height = decimalMaxHeight;
                width = decimalMaxDia;
                (type === 1) ? "" : diagonal = "diagonal=" + decimalMaxDiaWithHandle + "&";
            }

        }
        else {
            height = modelInfo.hIn;
            width = modelInfo.dIn;
            (modelInfo.diagIn === "N/A") ? "" : diagonal = "diagonal=" + modelInfo.diagIn + "&";
        }

        (size === 1) ? rackSize = "Full" : rackSize = "Half";
        (brand === 1) ? rackBrand = "Signature" : rackBrand = "Traex";

        colorsApiUrl = '/api/WarewashingConfiguration/GetRackColors?'
            + diagonal
            + 'height=' + height
            + '&width=' + width
            + '&rackSize=' + rackSize
            + '&rackBrand=' + rackBrand;
    }

    const searchClick = () => {
        setRackResults([]);
        setShowContactForm(false);
        setShowResults(0);

        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        };

        let rackApiUrl = '/api/warewashingconfiguration/getwarewashingracks?'
            + diagonal
            + 'height=' + height
            + '&width=' + width
            + '&rackSize=' + rackSize
            + '&rackBrand=' + rackBrand
            + (selectedColors.rackColorCode == null ? "" : '&rackColorCode=' + selectedColors.rackColorCode)
            + (selectedColors.riserColorCode == null ? "" : '&riserColorCode=' + selectedColors.riserColorCode)
            + (selectedColors.hasDifferentColoredTopRiser == null ? "" : '&hasDifferentColoredTopRiser=' + selectedColors.hasDifferentColoredTopRiser);

        fetch(rackApiUrl, requestOptions)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                else {
                    throw new Error('An error occured while finding your rack');
                }
            })
            .then(data => {
                setRackResults(data);
                data.length ? setShowContactForm(false) : setShowContactForm(true);
            })
            .catch((error) => {
                setRackError(error);
            })
            .finally(() => {
                setShowResults(true);
            });
    }
    
    return (
        <div className="wwConfigurator">
            <div className="wwConfigurator-container">
                <GlassType
                    type={type}
                    setType={setType} />
                <GlassInfo
                    type={type}
                    setInfoSelected={setInfoSelected}
                    searchBySize={searchBySize}
                    setSearchBySize={setSearchBySize}
                    modelInfo={modelInfo}
                    setModelInfo={setModelInfo}
                    sizeInfo={sizeInfo}
                    setSizeInfo={setSizeInfo}
                    disabled={!type} />
                <RackSize
                    size={size}
                    handleSizeChange={handleSizeChange}
                    disabled={!infoSelected} />
                <RackBrand
                    brand={brand}
                    handleBrandChange={handleBrandChange}
                    disabled={(size && infoSelected) ? false : true} />
                <RackColors
                    type={type}
                    modelInfo={modelInfo}
                    sizeInfo={sizeInfo}
                    size={size}
                    brand={brand}
                    searchBySize={searchBySize}
                    colorsApiUrl={colorsApiUrl}
                    setIsColorsSelected={setIsColorsSelected}
                    selectedColors={selectedColors}
                    setSelectedColors={setSelectedColors}
                    disabled={!infoSelected || (brand === null)} />
                <button
                    id="wwSearch-button"
                    className="btn"
                    disabled={(brand && infoSelected && isColorsSelected) ? false : true}
                    onClick={searchClick}>Find My Rack
                </button>
            </div>
            {showResults === 0 &&
                <div className="loader-section">
                    <div className="loader-spinner"></div>
                </div>}
            {showResults > 0 &&
                <Results
                rackError={rackError}
                rackResults={rackResults}
                setShowContactForm={setShowContactForm}
                setContactFormItemNumber={setContactFormItemNumber}
                isSales={isSales}
                />}
            {showContactForm &&
                <ContactForm
                setShowResults={setShowResults}
                showContactForm={showContactForm}
                contactFormItemNumber={contactFormItemNumber}
                searchBySize={searchBySize}
                type={type}
                modelInfo={modelInfo}
                size={size}
                brand={brand}
                selectedColors={selectedColors}
                height={height}
                width={width}
                diagonal={diagonal} />
            }
        </div>
    );
}

export default App;
