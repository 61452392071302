import React, { useState } from 'react';
import { useCurrentProjectIdContext } from "./project-builder-main.jsx";
import { useUpdateProduct } from '../../../components/project-builder-custom-hooks';
import Modal from 'react-modal';


export default function NotesModal({ readOnly, product }) {

    Modal.setAppElement('#main');

    const { currentProjectId } = useCurrentProjectIdContext();

    const { mutate: updateProduct, isLoading } = useUpdateProduct(currentProjectId);

    const [isOpen, setIsOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);

    const [notes, setNotes] = useState(product.Notes);

    const openModal = () => {
        setIsOpen(true);
    }

    const closeModal = () => {
        setIsOpen(false);
    }

    const handleFormSubmit = (event, payload) => {
        setIsEditing(false);
        event.preventDefault();
        updateProduct(payload);
    }

    const handleEdit = () => {
        setIsEditing(true);
    }

    const handleChange = (e) => {
        setNotes(e.target.value);
    }

    return (
        <>
            {(!readOnly || (readOnly && product.Notes?.length))
                ? <button class="link-btn underline" type="button" onClick={openModal}>Notes {product.Notes?.length ? "(1)" : ""}</button>
                : <div className="no-note">Notes</div>
            }
            <Modal
                isOpen={isOpen}
                onRequestClose={closeModal}
                className="project-builder project-builder-modal project-builder-modal--small"
            >
                <button className="project-builder-modal-close" onClick={closeModal}>
                    <svg id="header-ask-close-svg" className="icon icon-close"><use xlinkHref="#icon-close"></use></svg>
                </button>
                <div className='modal-title'>Notes</div>
                <p>Add your notes below for the relevant SKU.</p>

                {(product.Notes && !isEditing)
                    ? (
                        <div className="product-notes-list-item">
                            <div className="product-notes-media">
                                <img src={product.ImageUrl} alt={product.Title} />
                            </div>
                            <div className="product-notes-content">
                                <div className="product-notes-title"><strong>{product.Sku}</strong> {product.Title.length > 29 ? product.Title.slice(0,29) + "..." : product.Title}</div>
                                <div>{ notes }</div>
                            </div>
                            {readOnly ? null
                                : <div className="product-notes-actions ml-auto">
                                    <button class="btn--delete" type="button" disabled={isLoading} onClick={() => updateProduct({ id: product.Id, notes: null })}>
                                        <span className="icon icon--trash"></span>
                                    </button>

                                    <button class="link-btn mr-0 ml-1" type="button" disabled={isLoading} onClick={handleEdit}>
                                        <span className="icon icon--edit"></span>
                                    </button>
                                </div>
                            }
                        </div>
                    )
                    : (
                        <form className="project-builder-form" action="#" method="post" onSubmit={() => handleFormSubmit(event, { id: product.Id, notes: notes })}>

                            <div className="form-group form-group--relative">
                                <label className="sr-only" htmlFor="notes">Notes</label>
                                <textarea className="form-control" id="notes" name="notes" rows="5" maxlength="200" placeholder="Enter your comments" value={notes} onChange={handleChange}></textarea>
                                <div className="character-count">{notes?.length ? notes.length : "0"} / 200</div>
                            </div>
                            <div className="form-actions">
                                <button className='btn' type='submit' disabled={isLoading} >Submit</button>
                            </div>
                        </form>
                    )
                }
            </Modal>
        </>
    );
}