import React from 'react';
import ProductWishlistBtn from '../../../../shared/react/components/product-wishlist-btn.jsx';
import QuickAddWrapper from '../project-builder/quick-add-query-client.jsx';

export default function Results({ rackError, rackResults, setShowContactForm, setContactFormItemNumber, isSales }) {

    const handleContactClick = (e) => {
        e.preventDefault();
        let resultsIndex = e.currentTarget.getAttribute("index");
        let clickedItemNumber = rackResults[resultsIndex].ItemNumber;
        setContactFormItemNumber(clickedItemNumber);
        setShowContactForm(true);
    }

    return (
        <div className="wwSearch-results section">
            {!rackError && (rackResults.length > 0) &&
                rackResults.map((result, index) => (
                    <div key={index} className="row justify-content-md-center wwSearch-resultsItem">
                        <div className="col-lg-10 text-center product-image">
                            {index > 0 ? <hr /> : ""}
                            <a href={result.Url}>
                                <img src={result.Image} alt={result.ProductName} />
                            </a>
                        </div>
                        <div className="col-md-10 col-lg-9 col-xl-8">
                            <div className="intro-blk">
                                <p className="intro-blk-name">{result.ItemNumber}</p>
                                <a href={result.Url}>
                                    <h2 className="intro-blk-title">{result.ProductName}</h2>
                                </a>
                                <ul className="attributes-list">
                                    {(result.Certifications?.UnitedStates?.IsMadeInUsa) &&
                                        <li>
                                            <img src="/img/badges/badge-usa.svg" alt="Made in USA" />
                                        </li>
                                    }
                                    {(result.Certifications?.UnitedStates?.IsNSF) &&
                                        <li>
                                            <img src="/img/badges/badge-nsf.svg" alt="NSF" />
                                        </li>
                                    }
                                </ul>
                                <div className="product-price">
                                    ${result.ListPrice ? result.ListPrice.toFixed(2) : ""}
                                    <span> List price</span>
                                </div>
                                <div className="cta-actions">
                                    <a href="" class="btn no-arrow" index={index} onClick={event => handleContactClick(event)}>
                                        <span>Contact Sales</span>
                                    </a>
                                    {(isSales == "True")
                                        ? <QuickAddWrapper item={{ "NodeId": result.NodeId, "ItemNumber": result.ItemNumber, "ProductName": result.ProductName, "PageUrl": result.Url }} />
                                        : <ProductWishlistBtn id={result.ItemNumber} />
                                    }
                                </div>
                                <div className="intro-blk-copy">{result.Description}</div>

                                {(result.Bullets.length > 0) &&
                                    <ul className="intro-blk-info-list">
                                        {result.Bullets.map((item, index) => (
                                            <li key={index}>{item}</li>
                                        ))}
                                    </ul>
                                }
                            </div>
                        </div>
                        {(Object.keys(result.Specifications).length > 0) &&
                            <div class="col-md-10">
                                <div className="section">
                                    <div className="panel-section">
                                        <h2 className="panel-section-title">
                                            Product<span> Specifications</span>
                                        </h2>
                                        <div className="panel no-padding">
                                            {Object.entries(result.Specifications).map((entry) => {
                                                const [key, value] = entry;
                                                return (
                                                    <details className="panel-accordion" open>
                                                        <summary className="panel-accordion-title">{[key]}</summary>
                                                        <table className="table table-striped table-side-padding table-fixed no-margin">
                                                            <thead className="sr-only">
                                                                <tr>
                                                                    <th>Name</th>
                                                                    <th>Value</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {Object.entries(entry[1]).map((item) => {
                                                                    const [itemKey, itemValue] = item;
                                                                    return (
                                                                        <tr>
                                                                            <td>{itemKey}</td>
                                                                            <td>{itemValue}</td>
                                                                        </tr>
                                                                    )
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </details>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {(result.Accessories.length > 0) &&
                            <div className="col-md-10">
                                <details className="panel-section" open>
                                    <summary className="panel-section-title">Product <span>Accessories</span></summary>
                                    <div className="panel no-padding">
                                        <table className="table table-striped table-side-padding no-margin parts-table">
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Item #</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {result.Accessories.map((accessory) => (
                                                    accessory.ProductName ? (
                                                        <tr>
                                                            <td>{accessory.ProductName}</td>
                                                            {(accessory.Url) ? (
                                                                <td><a href={accessory.Url}>{accessory.ItemNumber}</a></td>
                                                            ) : (
                                                                <td>{accessory.ItemNumber}</td>
                                                            )}
                                                        </tr>
                                                    ) : null
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </details>
                            </div>
                        }
                        {(result.Parts.length > 0) &&
                            <div className="col-md-10">
                                <details className="panel-section" open>
                                    <summary className="panel-section-title">Product <span>Parts</span></summary>
                                    <div className="panel no-padding">
                                        <table className="table table-striped table-side-padding no-margin parts-table">
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Part #</th>
                                                    <th>Price</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {result.Parts.map((part) => (
                                                    <tr>
                                                        <td>{part.PartName}</td>
                                                        {(part.Url) ? (
                                                            <td><a href={part.Url}>{part.ItemNumber}</a></td>
                                                        ) : (
                                                            <td>{part.ItemNumber}</td>
                                                        )}
                                                        {(part.USListPrice > 0) ? (
                                                            <td>${part.USListPrice.toFixed(2)}</td>
                                                        ) : (
                                                            <td>-</td>
                                                        )}
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </details>
                            </div>
                        }
                    </div>
                ))
            }

            {!rackError && (rackResults.length > 0) &&
                <div className="row justify-content-md-center">
                    <div className="col-md-10" >
                        <p class="product-warranty-link"><a href="/vollrath-resources/warranty-info">Warranty Information</a></p>
                        <p class="terms-link"><a href="/terms-and-conditions-of-sale">Terms of Sale</a></p>
                    </div>
                </div>
            }

            {!rackError && (!rackResults.length) &&
                <div className="text-center">
                    <h3>No results found</h3>
                    <br />
                    <h4>We weren't able to find a rack matching your criteria.  Please fill out the form below to contact a sales representative.</h4>
                </div>
            }

            {rackError &&
                <div className="text-center">
                    <h3>An error occured while finding your rack.</h3>
                    <br />
                    <h4>Please visit our <a href="/contact-us">Contact Us</a> page to find your local Vollrath sales representative.</h4>
                </div>
            }
        </div>
    )
}